<template>
  <div class="lg:flex min-h-screen">
    <mobileNavbar class="lg:hidden" isDashboard> </mobileNavbar>
    <dashboardNavbar class="hidden lg:block sticky top-0" />
    <div class="bg-navy-900 h-auto w-full min-h-screen lg:p-10">
      <div
        class="font-ClashDisplay font-semibold text-white text-3xl p-6 lg:pl-6 lg:pb-0 lg:text-left"
      >
        Payout History<span class="text-sunray">.</span>
      </div>

      <div class="lg:w-full lg:px-6 lg:grid lg:grid-cols-5 lg:gap-10 lg:my-22r">
        <!-- Statistics -->
        <div class="mx-auto p-6 lg:p-0 lg:m-0 lg:col-span-2 lg:h-full hidden">
          <div class="bg-navy-500 p-6 rounded-xl my-2.5 lg:my-0 lg:h-96">
            <div
              class="flex text-white font-Satoshi text-lg font-medium tracking-wide mb-8"
            >
              Request Payouts
            </div>
            <div class="mb-3">
              <div class="font-Satoshi text-white font-medium text-left mb-2">
                Select Coin
              </div>
              <div>
                <input
                  type="text"
                  class="bg-gradientGray border-2 w-full border-gradientGray rounded-xl h-12 px-5 py-4 placeholder-opacity-75 text-white text-sm font-Satoshi font-medium tracking-wide bg-opacity-60 focus:ring-1 focus:ring-teal"
                  placeholder="3FZbgi29cpjq2GjdwV8eyHuJJnk..."
                />
              </div>
            </div>
            <div class="my-4">
              <div class="font-Satoshi text-white font-medium text-left mb-2">
                Enter Amount
              </div>
              <div class="relative flex items-center">
                <input
                  type="number"
                  class="bg-gradientGray relative border-2 w-full border-gradientGray rounded-xl h-12 pl-10 px-5 py-4 placeholder-opacity-75 text-white text-sm font-Satoshi font-medium tracking-wide bg-opacity-60 focus:ring-1 focus:ring-teal"
                  placeholder="0.00"
                />
                <img
                  class="font-Icons flex items-center text-lg text-white absolute left-3 cursor-pointer select-none"
                  src="@/assets/vectors/money-send.svg"
                />
              </div>
            </div>
            <button
              class="w-full bg-teal text-white font-Satoshi font-medium h-12 rounded-xl my-3 hover:opacity-75"
            >
              Request payout
            </button>
          </div>
        </div>

        <div
          class="mx-auto p-6 lg:col-span-3 lg:p-0 lg:w-full lg:h-full hidden"
        >
          <div class="bg-navy-500 p-6 rounded-xl my-2.5 lg:m-0 w-full lg:h-96">
            <div
              class="flex justify-between font-Satoshi text-white font-medium text-left w-full"
            >
              <div class="text-lg">Total Payouts</div>
              <div class="flex items-center">
                <div>USD</div>
                <img
                  src="@/assets/vectors/dropDownArrow.svg"
                  class="ml-2 h-1.5"
                  alt=""
                />
              </div>
            </div>
            <div class="lg:flex lg:flex-row lg:h-full lg:items-center">
              <div
                class="lg:flex lg:flex-col lg:justify-evenly lg:h-full lg:w-auto lg:text-left lg:pb-10 text-white font-Satoshi"
              >
                <div>
                  <div class="text-lg">This week</div>
                  <div class="text-3xl font-medium">$250.00</div>
                </div>
                <div>
                  <div class="text-lg">This month</div>
                  <div class="text-3xl font-medium">$1,000.00</div>
                </div>
              </div>
              <div class="px-10 pb-10 h-full w-full">
                <div class="border-teal border-2 h-full w-full"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Your earnings -->
        <div class="col-span-5">
          <payoutTable class="p-6 lg:p-0"></payoutTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import dashboardNavbar from "@/components/generalComponents/dashboardNavbar.vue";
import { mapGetters } from "vuex";
import payoutTable from "@/components/dashboardComponents/payoutTable.vue";

export default {
  components: { mobileNavbar, dashboardNavbar, payoutTable },
  name: "Dashboard",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
