<template>
  <div>
    <div class="bg-navy-500 h-24">
      <navbar class="hidden lg:flex" />
      <mobileNavbar class="lg:hidden" />
    </div>
    <div class="h-90 lg:h-110 bg-navy-500">
      <div
        class="flex justify-center item-center h-full max-w-screen-3xl mx-auto lg:relative"
      >
        <div
          class="lg:w-1/2 lg:m-auto flex flex-col justify-center items-center"
        >
          <div class="text-sunray font-Satoshi font-bold tracking-widest z-10">
            CONTACT US
          </div>
          <div
            class="font-ClashDisplay text-4.5xl w-3/4 lg:w-auto lg:text-5xl xl:text-6.5xl font-semibold text-white z-10"
          >
            Get in Touch With us<span class="text-sunray">.</span>
          </div>
          <div
            class="font-Satoshi text-white text-tiny md:text-base leading-7 w-11/12 mx-auto z-10 mt-5"
          >
            We would love to talk to you and see how we can help!
          </div>
          <img
            src="@/assets/vectors/01.svg"
            alt=""
            class="-mt-10 lg:m-0 absolute -z-50"
          />
        </div>
        <img
          src="@/assets/vectors/particles/whiteSquiggle.svg"
          alt=""
          class="hidden lg:block absolute w-24 left-0 bottom-14"
        />
        <img
          src="@/assets/vectors/particles/blueTriangleWhiteOutline.svg"
          alt=""
          class="hidden lg:block absolute w-7 top-20 transform right-20 z-40"
        />
      </div>
    </div>
    <div
      class="bg-white lg:flex lg:flex-row h-auto m-10 lg:my-32 justify-between px-5 md:px-20 lg:px-5 lg:w-3/4 max-w-screen-2xl mx-auto"
    >
      <div class="flex flex-col items-start h-90 justify-between">
        <div>
          <div
            class="text-sunray font-semibold tracking-wider font-CerebriPro text-left"
          >
            CONTACT FORM
          </div>
          <div
            class="text-6xl font-ClashDisplay font-semibold text-navy-500 text-left"
          >
            Contact<span class="text-sunray">.</span>
          </div>
        </div>
        <div class="text-left text-medium lg:hidden">
          Please fill out the form below to the best of your ability. We will
          get back to you as soon as possible.
        </div>
        <div>
          <div class="text-navy-500 font-bold text-left text-lg">
            Contact Email
          </div>
          <a
            href="mailto:contact@pursuitsolutions.com"
            class="text-navy-500 text-lg text-left hover:text-teal"
            >contact@pursuitcrypto.com</a
          >
        </div>
        <div>
          <div class="text-navy-500 text-lg font-bold text-left">
            Social Media
          </div>
          <div class="flex flex-row w-1/2 justify-between">
            <!-- <a
              href="https://twitter.com/PursuitCrypto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/vectors/TwitterNavy.svg"
                alt=""
                class="cursor-pointer"
              />
            </a> -->
            <a
              href="https://www.linkedin.com/company/pursuitcrypto/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/vectors/LinkedInNavy.svg" alt="" />
            </a>
          </div>
        </div>
        <img
          src="@/assets/vectors/02.svg"
          alt=""
          class="absolute -mt-10 -ml-20 w-80 h-40"
        />
      </div>
      <div class="lg:w-9/20 lg:flex lg:flex-col lg:h-full lg:pb-24">
        <div
          class="hidden lg:block text-lg font-medium font-Satoshi text-left leading-snug text-navy-500 w-full"
        >
          Please fill out the form below to the best of your ability. We will
          get back to you as soon as possible.
        </div>
        <form
          id="contactForm"
          ref="contactForm"
          @submit.prevent="handleSendingEmail"
          class="text-left text-sm w-full flex flex-col h-3/4 justify-evenly lg:mt-14"
        >
          <div>
            <div class="mt-12 flex justify-between">
              <label for="name" class="text-navy-500 font-bold"> Name </label>
              <span
                v-if="!$v.form.name.required && submitted"
                class="text-red-400 text-right w-full font-semibold"
              >
                Required
              </span>
            </div>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Johnathan Doe"
              class="py-4 px-5 h-11 bg-iceblue w-full rounded-md mt-1.5"
              v-model="form.name"
            />
          </div>
          <div class="flex flex-col lg:flex-row justify-between mt-5">
            <div class="lg:w-19/40">
              <div class="flex justify-between">
                <label for="email" class="text-navy-500 font-bold"
                  >Email Address</label
                >
                <span
                  v-if="!$v.form.email.required && submitted"
                  class="text-red-400 text-right font-semibold"
                  >Required</span
                >
                <span
                  v-if="!$v.form.email.email && submitted"
                  class="text-red-400 text-right font-semibold"
                  >Invalid</span
                >
              </div>

              <input
                type="text"
                id="email"
                name="email"
                class="py-4 px-5 h-11 bg-iceblue w-full rounded-md mt-1.5"
                placeholder="john@emailaddress.com"
                v-model="form.email"
              />
            </div>
            <div class="mt-5 lg:mt-0 lg:w-19/40">
              <div class="flex flex-row justify-between">
                <label for="phone" class="text-navy-500 font-bold"
                  >Phone <span class="text-gray-400">(Optional)</span></label
                >
                <span
                  v-if="!$v.form.phone.numeric && submitted"
                  class="text-red-400 text-right font-semibold"
                  >Invalid</span
                >
              </div>
              <input
                type="tel"
                id="phone"
                name="phone"
                class="py-4 px-5 h-11 bg-iceblue w-full rounded-md mt-1.5 focus:ring-2 focus:ring-teal"
                placeholder="1234567890"
                v-model="form.phone"
              />
            </div>
          </div>

          <div class="mt-5">
            <div class="flex flex-row justify-between">
              <label for="subject" class="text-navy-500 font-bold"
                >Subject</label
              >
              <span
                v-if="!$v.form.subject.required && submitted"
                class="text-red-400 text-right font-semibold"
                >Required</span
              >
            </div>
            <input
              type="text"
              id="subject"
              name="subject"
              class="py-4 px-5 h-11 bg-iceblue w-full rounded-md mt-1.5"
              placeholder="Inquiry"
              v-model="form.subject"
            />
          </div>
          <div class="mt-5">
            <div class="flex flex-row justify-between">
              <label for="message" class="text-navy-500 font-bold"
                >Message</label
              >
              <span
                v-if="!$v.form.message.required && submitted"
                class="text-red-400 text-right font-semibold"
                >Required</span
              >
            </div>
            <textarea
              type="text"
              class="py-4 px-5 h-48 bg-iceblue w-full rounded-md break-all resize-none mt-1.5"
              placeholder="Enter your message..."
              name="message"
              id="message"
              v-model="form.message"
            />
          </div>
          <input
            type="submit"
            class="font-semibold w-5/6 lg:w-2/3 h-12 text-white lg:text-base rounded-xl flex items-center justify-center mx-auto tracking-widest mt-5 hover:bg-opacity-75 cursor-pointer"
            :class="{
              'bg-green-500': submitIsSuccess,
              'bg-red-500': submitIsError,
              'bg-teal': submitIsSuccess == submitIsError,
            }"
            :value="submitButtonValue"
          />
        </form>
      </div>

      <img
        src="@/assets/vectors/particles/blackTriangleOutline.svg"
        alt=""
        class="hidden lg:block absolute w-6 left-24 bottom-12"
      />
      <img
        src="@/assets/vectors/particles/redCircleBlackOutline.svg"
        alt=""
        class="hidden lg:block absolute w-12 right-32 bottom-48"
      />
    </div>
    <customFooter />
  </div>
</template>

<script>
import navbar from "@/components/generalComponents/navbar.vue";
import customFooter from "@/components/generalComponents/customFooter.vue";
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import { mapActions } from "vuex";

import { required, numeric, email } from "vuelidate/lib/validators";

export default {
  name: "Contact",
  components: {
    navbar,
    customFooter,
    mobileNavbar,
  },
  title() {
    return `Pursuit Crypto - Contact `;
  },
  data() {
    return {
      submitButtonValue: "Send message",
      submitIsSuccess: false,
      submitIsError: false,
      submitted: false,
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        numeric,
      },
      subject: {
        required,
      },
      message: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["sendContactEmail"]),
    handleSendingEmail() {
      this.submitButtonValue = "...";
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendContactEmail(this.form).then(
          (result) => {
            this.submitButtonValue = "Message sent!";
            console.log("SUCCESS!", result);
            this.submitIsSuccess = true;
          },
          (error) => {
            this.submitButtonValue = "Error!";
            console.log("FAILED...", error.text);
            this.submitIsError = true;
          }
        );
      } else {
        this.submitButtonValue = "Send message";
      }
    },
  },
};
</script>
