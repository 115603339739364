export default {
  btc: {
    name: "bitcoin",
    symbol: "btc",
  },
  eth: {
    name: "ethereum",
    symbol: "eth",
  },
  usdc: {
    name: "usd-coin",
    symbol: "usdc",
  },
  ltc: {
    name: "litecoin",
    symbol: "ltc",
  },
  doge: {
    name: "dogecoin",
    symbol: "doge",
  },
};
