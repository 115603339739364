<template id="04">
  <div
    class="bg-white h-screen p-8 py-24 md:p-32 lg:pb-0 lg:p-10 lg:flex lg:flex-row lg:justify-center items-center max-w-screen-2xl max-h-1080 mx-auto"
  >
    <div
      class="flex lg:relative flex-col lg:flex-row items-center w-full h-full lg:h-auto justify-between"
    >
      <img
        src="@/assets/images/CompositionLP4.png"
        alt=""
        class="hidden lg:inline 3xl:w-160 3xl:h-160"
      />
      <div
        class="lg:w-1/2 lg:h-90 h-full justify-between flex flex-col lg:justify-evenly"
      >
        <div class="z-10">
          <div
            class="text-sunray font-CerebriPro font-semibold md:font-bold text-sm md:tracking-widest md:text-base text-left z-10 pt-4"
          >
            LEARN ABOUT WHAT WE CAN DO FOR YOU
          </div>
          <div
            class="text-left text-3xl md:text-4.5xl leading-tight lg:pr-0 lg:text-6xl font-ClashDisplay font-semibold pt-4"
          >
            Let's talk<span class="text-sunray">.</span>
          </div>
        </div>

        <router-link
          to="/contact"
          class="hover:bg-opacity-75 cursor-pointer bg-teal h-12 w-32 md:h-20 md:w-56 lg:h-16 lg:w-48 -mt-24 md:-mt-20 lg:mt-0 rounded-full text-white font-CerebriPro font-bold flex flex-row items-center justify-center z-50"
        >
          Contact us
        </router-link>
        <img
          src="@/assets/vectors/04.svg"
          alt=""
          class="absolute -ml-20 lg:w-90 lg:h-72 lg:-ml-14 lg:-mt-15"
        />
      </div>
      <img
        src="@/assets/images/CompositionLP4.png"
        alt=""
        class="md:w-144 lg:hidden"
      />
      <img
        src="@/assets/vectors/particles/blackCircleOutline.svg"
        alt=""
        class="hidden absolute lg:block w-4 bottom-96 xl:bottom-120"
      />
      <img
        src="@/assets/vectors/particles/blueRectangleBlackOutline.svg"
        alt=""
        class="hidden absolute lg:block w-7 bottom-0 left-96"
      />
      <img
        src="@/assets/vectors/particles/redSquareBlackOutline.svg"
        alt=""
        class="hidden absolute lg:block w-12 -top-36 right-96 xl:right-136"
      />
      <img
        src="@/assets/vectors/particles/blackSquiggle.svg"
        alt=""
        class="hidden absolute lg:block w-22 right-10 bottom-0 transform -rotate-45"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "thirdSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
