import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import { db, auth } from "@/firebase.js";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  setDoc,
  addDoc,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";

import createPersistedState from "vuex-persistedstate";
import axios from "axios";
var baseURL;
if (process.env.NODE_ENV == "production") {
  baseURL = "https://us-central1-pursuit-crypto.cloudfunctions.net";
} else {
  const port = 5001;
  baseURL = `http://localhost:${port}/pursuit-crypto/us-central1`;
}

Vue.use(Vuex);

const crypto = ["bitcoin", "ethereum", "usd-coin", "litecoin", "dogecoin"];
const cryptoSymbols = ["btc", "eth", "usdc"];
const currencies = ["usd", "cad"];

export const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
      token: null,
    },
    cryptoPrices: {
      data: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    cryptoPrices(state) {
      return state.cryptoPrices;
    },
  },
  mutations: {
    setLoggedIn(state, value) {
      state.user.loggedIn = value;
    },
    setToken(state, value) {
      state.user.token = value;
    },
    setUserData(state, data) {
      state.user.data = data;
    },
    setCryptoPrices(state, data) {
      state.cryptoPrices.data = data;
    },
  },
  actions: {
    async commitUser({ commit }, user) {
      try {
        commit("setLoggedIn", user !== null);
        if (user) {
          const userRef = doc(db, "users", user.uid);
          await getDoc(userRef).then((doc) => {
            doc = doc.data();
            commit("setUserData", {
              email: user.email,
              firstName: doc.firstName,
              lastName: doc.lastName,
              luxorUsername: doc.luxorUsername,
              uid: user.uid,
            });
          });
          user.getIdToken().then((token) => {
            commit("setToken", token);
          });
        } else {
          commit("setUserData", null);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getWallets(context) {
      //   if (!this.getters.user.loggedIn) return;
      //   const q = query(
      //     collection(db, "wallets"),
      //     where("userID", "==", uid),
      //     orderBy("created", "asc")
      //   );
      //   return await getDocs(q)
      //     .then((snapshot) => {
      //       let tempDocs;
      //       tempDocs = snapshot.docs.map((doc) => {
      //         return { id: doc.id, ...doc.data() };
      //       });
      //       return tempDocs;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });

      if (!this.getters.user.loggedIn) return;

      const params = new URLSearchParams([
        ["userID", this.getters.user.data.uid],
      ]);
      var config = {
        method: "get",
        url: `${baseURL}/app/wallets`,
        headers: {
          Authorization: "Bearer " + this.getters.user.token,
        },
        params: params,
      };

      return await axios(config).catch(function (error) {
        console.log(error);
      });
    },
    async updateWallet(context, walletInfo) {
      console.log(walletInfo);
      if (!this.getters.user.loggedIn) return;
      const params = new URLSearchParams([
        ["userID", this.getters.user.data.uid],
      ]);
      var config = {
        method: "patch",
        url: `${baseURL}/app/wallets/${walletInfo.id}`,
        headers: {
          Authorization: "Bearer " + this.getters.user.token,
          "Content-Type": "application/json",
        },
        params: params,
        data: walletInfo,
      };

      return await axios(config).catch(function (error) {
        console.log(error);
      });

      //   return await setDoc(
      //     doc(db, "wallets", walletInfo.id),
      //     {
      //       address: walletInfo.address,
      //       threshold: walletInfo.threshold ? walletInfo.threshold : null,
      //       updated: serverTimestamp(),
      //     },
      //     { merge: true }
      //   ).catch((error) => {
      //     console.log(error);
      //   });
    },
    async createWallet(context, coin) {
      if (!this.getters.user.loggedIn) return;
      const data = {
        userID: this.getters.user.data.uid,
        coin,
      };

      var config = {
        method: "post",
        url: `${baseURL}/app/wallets`,
        headers: {
          Authorization: "Bearer " + this.getters.user.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      return await axios(config).catch(function (error) {
        console.log(error);
      });
    },

    async signOutUser() {
      this.dispatch("setLoggedIn", false);
      return await auth
        .signOut()
        .then(() => {
          router.push("/");
        })
        .catch((error) => {
          console.error("Sign Out Error", error);
        });
    },

    async sendContactEmail(context, formData) {
      console.log(formData);
      var config = {
        method: "post",
        url: `${baseURL}/app/contactEmail`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getters.user.token,
        },
        data: {
          formData,
        },
      };

      return await axios(config).catch(function (error) {
        console.log(error);
      });
    },

    async updateCryptoPrices({ dispatch }) {
      var config = {
        method: "get",
        url: "https://api.coingecko.com/api/v3/simple/price",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          ids: crypto.toString(),
          vs_currencies: currencies.toString(),
        },
      };

      await axios(config)
        .then(function (response) {
          dispatch("commitCryptoPrices", response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async commitCryptoPrices({ commit }, value) {
      try {
        commit("setCryptoPrices", value.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getRevenue(context, interval) {
      if (!this.getters.user.loggedIn) return;

      var data = JSON.stringify({
        uname: this.getters.user.data.luxorUsername,
        interval: interval,
      });

      var config = {
        method: "post",
        url: `${baseURL}/metrics-getRevenue`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      return await axios(config).catch(function (error) {
        console.log(error);
      });
    },

    async getAllRevenue(context, coin) {
      if (!this.getters.user.loggedIn) return;

      const params = new URLSearchParams([
        ["userID", this.getters.user.data.uid],
      ]);
      var config = {
        method: "get",
        url: `${baseURL}/app/revenue`,
        headers: {
          Authorization: "Bearer " + this.getters.user.token,
        },
        params: params,
      };

      return axios(config).catch(function (error) {
        console.log(error);
      });
    },

    async getChartRevenue(context, coins) {
      if (!this.getters.user.loggedIn) return;

      let uid = this.getters.user.data.uid;
      let startDates = {};

      const q = query(
        collection(db, "startDates"),
        where("userID", "==", uid)
        // where("coin", "in", [coins])
      );

      await getDocs(q)
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            startDates[doc.data().coin] = doc.data().startDate;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      var data = JSON.stringify({
        uname: this.getters.user.data.luxorUsername,
        startDates,
      });

      var config = {
        method: "post",
        url: `${baseURL}/metrics-getChartRevenue`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      return axios(config).catch(function (error) {
        console.log(error);
      });
    },

    async getPayouts(context) {
      if (!this.getters.user.loggedIn) return;
      const params = new URLSearchParams([
        ["userID", this.getters.user.data.uid],
      ]);
      var config = {
        method: "get",
        url: `${baseURL}/app/payouts`,
        params: params,
        headers: {
          Authorization: "Bearer " + this.getters.user.token,
        },
      };

      return axios(config).catch(function (error) {
        console.log(error);
      });
    },

    async getAllMinerInfo(context) {
      if (!this.getters.user.loggedIn) return;
      const params = new URLSearchParams([
        ["userID", this.getters.user.data.uid],
      ]);
      var config = {
        method: "get",
        url: `${baseURL}/app/minerInfo`,
        params: params,
        headers: {
          Authorization: "Bearer " + this.getters.user.token,
        },
      };

      return axios(config).catch(function (error) {
        console.log(error);
      });
    },
  },
  plugins: [createPersistedState()],
});
