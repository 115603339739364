<!-- loginNav -->
<template>
  <div class="text-white font-CerebriPro w-full z-0">
    <div
      class="max-w-screen-3xl flex items-center justify-between h-24 mx-auto px-24"
    >
      <div class="flex items-center">
        <router-link to="/">
          <img src="@/assets/vectors/logo.svg" alt="" />
        </router-link>
        <router-link
          class="font-CerebriPro ml-4 font-bold hover:text-sunray"
          to="/"
          >Pursuit Crypto</router-link
        >
      </div>
      <div class="flex items-center">
        <router-link class="mr-6 font-semibold hover:text-sunray" :to="linkTo">
          {{ rightText }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginNavbar",
  props: {
    rightText: String,
    linkTo: String,
  },
  methods: {},
};
</script>
