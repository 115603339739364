<template id="01">
  <div
    class="bg-navy-500 px-6 py-9 md:px-14 md:py-16 lg:p-0 h-screen max-h-1080"
  >
    <div
      class="max-w-screen-3xl flex flex-col justify-between h-full lg:block lg:relative mx-auto"
    >
      <navbar class="hidden lg:flex"> </navbar>

      <!-- Big text -->
      <div
        class="h-4/5 lg:h-auto lg:px-32 lg:flex lg:flex-row lg:pt-20 1xl:pt-28"
      >
        <div class="h-full lg:h-auto lg:w-3/5 flex flex-col justify-between">
          <div
            class="text-sunray font-CerebriPro font-bold text-sm tracking-widest md:text-base text-left z-10"
          >
            PURSUIT OF ALL THINGS CRYPTO
          </div>
          <div
            class="text-white text-left text-4.5xl md:-mt-20 lg:-mt-0 md:text-6xl leading-tight lg:leading-normal lg:text-4xl 1xl:text-6xl font-ClashDisplay font-semibold z-10"
          >
            Building the Future of Crypto
            <br class="hidden 3xl:block" />Mining
            <span class="text-sunray">.</span>
          </div>
          <div
            class="text-white text-sm tracking-widest font-semibold lg:tracking-normal xl:text-lg lg:font-bold font-CerebriPro text-left md:text-2xl lg:text-base z-10"
          >
            Creating an all-inclusive Crypto Mining experience for all
          </div>
          <div
            class="text-white text-tiny md:text-xl md:leading-relaxed xl:text-base lg:font-semibold text-left lg:text-sm z-10"
          >
            Pursuit Crypto strives to make Crypto Mining accessible to anyone
            and everyone. We welcome all experience levels to join us in being
            part of a new era of investment.
          </div>
          <button
            @click="scroll('02')"
            class="hover:bg-opacity-75 bg-teal w-1/2 md:w-1/3 h-15 tracking-wide text-sm md:text-lg lg:h-14 lg:w-32 1xl:text-base 1xl:h-16 1xl:w-48 rounded-full text-white font-atoshi lg:font-bold flex flex-row items-center justify-center z-10"
          >
            Learn More
          </button>
          <div class="flex flex-row items-center">
            <img
              src="@/assets/vectors/mouseScroll.svg"
              class="h-5 md:h-10 lg:h-6"
            />
            <span
              class="text-white tracking-wide text-sm ml-3 md:text-2xl lg:text-base lg:font-semibold lg:ml-5"
            >
              Scroll Down to Learn More
            </span>
          </div>
          <img
            src="@/assets/vectors/01.svg"
            class="absolute -z-50 -mt-7 -ml-20 lg:h-25"
          />
        </div>
        <div class="hidden w-2/5 lg:flex justify-end">
          <img
            src="@/assets/images/compositionLP1.png"
            class="absolute lg:block lg:h-90 xl:h-100 1xl:h-136"
          />
          <img
            src="@/assets/vectors/vectorLP1.svg"
            class="lg:block lg:mr-4 lg:h-90 xl:h-100 1xl:h-136"
          />
        </div>
      </div>
      <div
        class="border-dashed border border-accent lg:mx-32 lg:mt-8 1xl:mt-16"
      ></div>
      <div
        class="flex flex-row items-center justify-between mx-4 md:mx-10 lg:w-3/5 lg:mx-32 lg:pt-8 3xl:w-1/2 3xl:pt-16"
      >
        <img
          src="@/assets/vectors/BTC.svg"
          alt=""
          class="w-8 md:w-13 lg:w-14"
        />
        <img
          src="@/assets/vectors/Ethereum.svg"
          alt=""
          class="w-8 md:w-13 lg:w-14"
        />
        <img
          src="@/assets/vectors/Litecoin.svg"
          alt=""
          class="w-8 md:w-13 lg:w-14"
        />
        <img
          src="@/assets/vectors/Dogecoin.svg"
          alt=""
          class="w-8 md:w-13 lg:w-14"
        />
        <img src="@/assets/vectors/K.svg" alt="" class="w-8 lg:w-14" />
      </div>

      <img
        src="@/assets/vectors/particles/whiteSquiggle.svg"
        alt=""
        class="hidden lg:block absolute w-24 right-0 bottom-144"
      />
      <img
        src="@/assets/vectors/particles/redSquareWhiteOutline.svg"
        alt=""
        class="hidden lg:block absolute w-12 right-32 bottom-48"
      />
      <img
        src="@/assets/vectors/particles/blueCircleWhiteOutline.svg"
        alt=""
        class="hidden lg:block absolute w-7 top-100 left-12 z-40"
      />
    </div>
  </div>
</template>

<script>
import navbar from "@/components/generalComponents/navbar.vue";

export default {
  name: "firstSection",
  components: {
    navbar,
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
