<template>
  <div class="lg:flex">
    <mobileNavbar class="lg:hidden" isDashboard> </mobileNavbar>
    <dashboardNavbar class="hidden lg:block sticky top-0" />
    <div id="earnings" class="bg-navy-900 h-auto w-full lg:p-10">
      <div class="flex items-center w-full justify-between">
        <div
          class="font-ClashDisplay font-semibold text-white text-3xl p-6 lg:pl-6 lg:pb-0 lg:text-left"
        >
          Welcome, {{ user.data.firstName }}<span class="text-sunray">.</span>
        </div>
        <div class="hidden lg:flex items-center relative p-6 lg:pl-6 lg:pb-0">
          <dropdown
            :title="currentCurrency"
            side="right"
            :items="currencyDropdownItems"
            textSize="xl"
            hasBackground
            v-model="currentCurrency"
          />
        </div>
      </div>

      <div class="lg:hidden px-4">
        <dropdown
          :title="currentCurrency"
          side="left"
          :items="currencyDropdownItems"
          textSize="xl"
          v-model="currentCurrency"
        />
      </div>

      <!-- Statistics -->
      <div class="mx-auto p-6 lg:py-3 lg:flex lg:w-full lg:justify-between">
        <div class="font-Satoshi text-white font-medium text-left lg:hidden">
          Statistics
        </div>
        <div
          class="bg-navy-500 flex flex-row h-22 p-4 rounded-xl my-2.5 lg:h-auto lg:p-6 lg:w-1/3 lg:mr-6"
        >
          <img
            src="@/assets/vectors/bucket.svg"
            class="p-1.5 mr-2 lg:hidden"
            alt=""
          />
          <div
            class="hidden bg-sunray rounded-xl mr-4 lg:flex items-center justify-center h-16 w-16"
          >
            <img src="@/assets/vectors/white-bucket.svg" alt="" />
          </div>
          <div class="flex flex-col items-start justify-around h-full">
            <div
              class="uppercase text-bgGray font-Satoshi font-medium text-sm tracking-wider"
              :class="{ 'animate-pulse': !minerInfo[currentCoin] }"
            >
              Miners Online
            </div>
            <div class="text-white font-Satoshi font-medium text-lg">
              {{ minerInfo[currentCoin] }}
            </div>
            <span
              class="uppercase"
              :class="{ 'text-navy-500': !minerInfo }"
              >{{
            }}</span>
          </div>
        </div>
        <div
          class="bg-navy-500 flex flex-row h-22 p-4 rounded-xl my-2.5 lg:h-auto lg:p-6 lg:w-1/3 lg:mr-6"
        >
          <img
            src="@/assets/vectors/dollar-circle.svg"
            class="p-1.5 mr-2 lg:hidden"
            alt=""
          />

          <div
            class="hidden bg-teal rounded-xl mr-4 lg:flex items-center justify-center h-16 w-16"
          >
            <img src="@/assets/vectors/white-dollar-circle.svg" alt="" />
          </div>

          <div
            class="flex flex-col items-start justify-around h-full"
            :class="{ 'animate-pulse': !revenue.last30days }"
          >
            <div
              class="uppercase text-bgGray font-Satoshi font-medium text-sm tracking-wider"
            >
              Revenue in Last 30 days
            </div>
            <div class="text-white font-Satoshi font-medium text-lg">
              <span v-if="currentCurrency">
                {{
                  revenue.last30days
                    | currencyConversion(
                      currentCurrency,
                      currentCoin,
                      cryptoPrices,
                      customPrices,
                      crypto
                    )
                    | moneyFormat
                }}
              </span>
              <span v-else>
                {{
                  revenue.last30days
                    | currencyConversion(
                      currentCurrency,
                      currentCoin,
                      cryptoPrices,
                      customPrices,
                      crypto
                    )
                }}
              </span>

              <span
                class="uppercase"
                :class="{ 'text-navy-500': !revenue.last30days }"
                >{{ currentCurrency ? currentCurrency : currentCoin }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="bg-navy-500 flex flex-row h-22 p-4 rounded-xl my-2.5 lg:h-auto lg:p-6 lg:w-1/3"
        >
          <img
            src="@/assets/vectors/coin.svg"
            class="p-1.5 mr-2 lg:hidden"
            alt=""
          />

          <div
            class="hidden bg-redpink rounded-xl mr-4 lg:flex items-center justify-center h-16 w-16"
          >
            <img src="@/assets/vectors/white-coin.svg" alt="" />
          </div>
          <div
            class="flex flex-col items-start justify-around h-full"
            :class="{ 'animate-pulse': !revenue.lifetime }"
          >
            <div
              class="uppercase text-bgGray font-Satoshi font-medium text-sm tracking-wider"
            >
              Lifetime revenue
            </div>
            <div class="text-white font-Satoshi font-medium text-lg">
              <span v-if="currentCurrency">
                {{
                  revenue.lifetime
                    | currencyConversion(
                      currentCurrency,
                      currentCoin,
                      cryptoPrices,
                      customPrices,
                      crypto
                    )
                    | moneyFormat
                }}
              </span>
              <span v-else>
                {{
                  revenue.lifetime
                    | currencyConversion(
                      currentCurrency,
                      currentCoin,
                      cryptoPrices,
                      customPrices,
                      crypto
                    )
                }}
              </span>
              <span
                class="uppercase"
                :class="{ 'text-navy-500': !revenue.lifetime }"
                >{{ currentCurrency ? currentCurrency : currentCoin }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="lg:flex lg:h-110 flex-grow">
        <!-- Current Coin -->

        <div class="mx-auto p-6 lg:pr-0 lg:py-3 lg:mr-6 lg:w-1/3 lg:h-full">
          <div
            class="flex justify-between font-Satoshi text-white font-medium text-left w-full lg:hidden"
          >
            <div class="">Current Coin</div>
          </div>
          <div class="bg-navy-500 p-6 rounded-xl my-2.5 lg:my-0 lg:h-full">
            <div
              class="hidden lg:flex justify-between font-Satoshi text-white font-medium text-left w-full pb-6"
            >
              <div class="flex items-center w-64">Current Coin</div>
            </div>
            <div
              class="bg-gradient-to-l flex flex-col justify-between bg-gradientGray bg-opacity-60 rounded-xl p-7 text-white font-Satoshi relative"
            >
              <div class="flex items-center mb-9">
                <div class="h-12 w-12 overflow-hidden">
                  <img :src="getImgUrl(currentCoin, 'Yellow')" alt="" />
                </div>
                <div
                  class="ml-4 text-lg lg:text-xl 2xl:text-2.5xl font-medium flex items-center"
                >
                  <span v-if="currentCurrency">
                    {{
                      customPrices[currentCoin]
                        ? customPrices[currentCoin]
                        : cryptoPrices.data[crypto[currentCoin].name][
                            currentCurrency
                          ] | moneyFormat
                    }}
                  </span>
                  <span v-else>
                    {{
                      customPrices[currentCoin]
                        ? customPrices[currentCoin]
                        : cryptoPrices.data[crypto[currentCoin].name].usd
                          | moneyFormat
                    }}
                  </span>
                  <span class="uppercase ml-2">
                    {{ currentCurrency ? currentCurrency : "USD" }}
                  </span>
                  <span
                    v-if="customPrices[currentCoin]"
                    class="block cursor-pointer hover:rotate-180 transform ease-in-out duration-300 font-Icons text-bgGray hover:text-opacity-100 text-opacity-50 ml-2"
                    @click="customPrices[currentCoin] = null"
                  >
                    refresh
                  </span>
                </div>
              </div>
              <div class="flex items-center">
                <div class="flex items-center relative">
                  <dropdown
                    :title="currentCoin"
                    side="left"
                    :items="coinDropdownItems"
                    textSize="xl"
                    v-model="currentCoin"
                  />
                </div>
              </div>

              <img
                :src="getImgUrl(currentCoin, 'Gray')"
                alt=""
                class="absolute top-0 left-0 opacity-25 pointer-events-none"
              />
            </div>
            <div class="border-b my-9 border-gradientGray"></div>
            <div>
              <div class="font-Satoshi text-white font-medium text-left mb-2">
                Set Custom Coin Price
              </div>
              <div class="relative flex items-center">
                <input
                  type="number"
                  class="bg-gradientGray relative border-2 w-full border-gradientGray rounded-xl h-12 pl-10 px-5 py-4 placeholder-opacity-75 text-white text-sm font-Satoshi font-medium tracking-wide bg-opacity-60 focus:ring-1 focus:ring-teal"
                  placeholder="Set a custom price..."
                  v-model="customPrices[currentCoin]"
                />
                <div
                  src="/img/money-send.5fec3aee.svg"
                  class="font-Icons flex items-center text-lg text-white absolute left-3 cursor-pointer select-none"
                >
                  attach_money
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Extrapolated Earnings -->

        <div class="mx-auto p-6 lg:py-3 lg:pl-0 lg:w-2/3 lg:h-full hidden">
          <div class="font-Satoshi text-white font-medium text-left lg:hidden">
            Extrapolated Earnings
          </div>
          <div class="bg-navy-500 p-6 rounded-xl my-2.5 lg:my-0 lg:h-full">
            <div
              class="hidden lg:block font-Satoshi text-white font-medium text-left"
            >
              Extrapolated Earnings
            </div>
            <div class="h-full w-full flex items-center justify-center px-6">
              <apexchart
                width="100%"
                height="90%"
                type="bar"
                ref="chart"
                :options="options"
                :series="series"
                class="w-full"
              ></apexchart>
            </div>
          </div>
        </div>

        <div class="mx-auto p-6 lg:py-3 lg:pl-0 lg:w-2/3 lg:h-full">
          <div
            class="bg-navy-500 p-6 rounded-xl my-2.5 lg:my-0 lg:h-full flex items-center justify-center"
          >
            <div class="text-white font-Satoshi text-3xl text-opacity-40">
              Coming soon...
            </div>
          </div>
        </div>
      </div>

      <!-- Your earnings -->
      <payoutTable limited class="p-6"></payoutTable>
    </div>
  </div>
</template>

<script>
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import dashboardNavbar from "@/components/generalComponents/dashboardNavbar.vue";
import { mapGetters, mapActions } from "vuex";
import dropdown from "../components/generalComponents/dropdown.vue";
import crypto from "@/util/crypto.js";
import payoutTable from "@/components/dashboardComponents/payoutTable.vue";

export default {
  components: { dashboardNavbar, mobileNavbar, dropdown, payoutTable },
  name: "Dashboard",
  async created() {
    // this.coinList.forEach(async (coin) => {
    //   await this.getAllRevenue(coin)
    //     .then((response) => {
    //       this.revenue30day[coin] = response.data.last30days;
    //       this.revenueLifetime[coin] = response.data.lifetime;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // });

    // await this.getChartRevenue(this.coinList).then((response) => {
    //   this.coinList.forEach((coin) => {
    //     this.chartCategories[coin] = [];
    //     this.chartData[coin] = [];
    //     for (let entry in response.data) {
    //       this.chartCategories[coin].push(
    //         parseInt(entry.slice(entry.search(/\d/)))
    //       );
    //       this.chartData[coin].push(response.data[entry]);
    //     }
    //   });
    //   this.setChartData();
    // });

    await this.getAllMinerInfo().then((response) => {
      response.data.forEach((doc) => {
        this.minerInfo[doc.coin] = doc.amount;
      });
    });

    await this.getAllRevenue().then((response) => {
      this.revenue = response.data;
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
      cryptoPrices: "cryptoPrices",
    }),
  },
  data() {
    return {
      coinList: ["btc", "eth"],
      revenue30day: {
        btc: [],
        eth: [],
      },
      minerInfo: {},
      revenue: {},
      revenueLifetime: { btc: [], eth: [] },
      chartData: {},
      chartCategories: {},
      currentCoin: "btc",
      currentCurrency: null,
      customPrices: {},
      crypto: crypto,
      coinDropdownItems: {
        "Bitcoin (BTC)": {
          isSelected: true,
          value: "btc",
          disabled: false,
        },
        "Ethereum (ETH)": {
          isSelected: false,
          value: "eth",
          disabled: true,
        },
        "Litecoin (LTC)": {
          isSelected: false,
          value: "ltc",
          disabled: true,
        },
        "Dogecoin (DOGE)": {
          isSelected: false,
          value: "doge",
          disabled: true,
        },
        "USD-Coin (USDC)": {
          isSelected: false,
          value: "usdc",
          disabled: true,
        },
      },
      currencyDropdownItems: {
        Crypto: {
          isSelected: true,
          value: null,
        },
        USD: {
          isSelected: false,
          value: "usd",
        },
        CAD: {
          isSelected: false,
          value: "cad",
        },
      },
      options: {
        chart: {
          id: "vuechart-example",
          fontFamily: "Satoshi, Arial, sans-serif",
          fontSize: "24px",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        xaxis: {
          tickPlacement: "between",
          //   type: "numeric",
          //   categories: [1646259077, 1646259077, 1646259077, 1646259077],

          labels: {
            formatter: function (value, timestamp) {
              return new Date(timestamp); // The formatter function overrides format property
            },
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
            style: {
              fontSize: "14px",
              colors: "#ffffff",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "14px",
              colors: "#ffffff",
              align: "left",
            },
            offsetX: -13,
          },
        },
        fill: {
          colors: [
            function ({ dataPointIndex }) {
              if (dataPointIndex == 0) return "#FCAE17";
              else return "#23A9D0";
            },
          ],
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: {
          enabled: true,
          style: {
            colors: "#FFFFFF",
            fontSize: "122px",
          },
        },

        grid: {
          show: true,
          borderColor: "#676773",
          strokeDashArray: 10,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    };
  },
  filters: {
    currencyConversion: function (
      value,
      currentCurrency,
      currentCoin,
      cryptoPrices,
      customPrices,
      crypto
    ) {
      if (!value) return "";
      if (!currentCurrency) return String(value).substring(0, 7);
      else {
        if (customPrices[currentCoin]) {
          return value * customPrices[currentCoin];
        } else
          return (
            value * cryptoPrices.data[crypto[currentCoin].name][currentCurrency]
          );
      }
    },
  },
  methods: {
    ...mapActions(["getAllRevenue", "getChartRevenue", "getAllMinerInfo"]),
    changeData() {
      this.series = [{ data: [this.chartData] }];
    },
    getImgUrl(coin, color) {
      return require(`@/assets/vectors/dashboard${color}-(${coin}).svg`);
    },
    setChartData() {
      this.options.xaxis.categories = [
        1646259077, 1646259077, 1646259077, 1646259077,
      ];
      //   this.options.xaxis.categories = this.chartCategories["btc"]
      //     ? this.chartCategories["btc"]
      //     : [];

      this.series = [
        {
          name: this.currentCoin,
          data: this.chartData["btc"] ? this.chartData["btc"] : [],

          //   this.chartCategories["btc"]
          //     ? this.chartCategories["btc"]
          //     : [],
        },
      ];
    },
  },
};
</script>
