var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-navy-500 lg:h-screen w-screen relative"},[_c('mobileNavbar',{staticClass:"lg:hidden"}),_c('loginNavbar',{staticClass:"hidden lg:block lg:absolute z-40",attrs:{"rightText":"Don’t have an account? Contact us!","linkTo":"/contact"}}),_c('div',{staticClass:"lg:flex lg:relative lg:flex-row lg:justify-evenly items-center h-full lg:max-w-screen-3xl mx-auto"},[_vm._m(0),_c('div',{staticClass:"lg:w-1/2 lg:bg-navy-800 z-30 lg:h-full lg:flex lg:items-center lg:justify-center lg:py-24"},[_c('div',{staticClass:"p-3 lg:w-full lg:h-full lg:flex lg:justify-center lg:items-center"},[_c('div',{staticClass:"bg-white p-8 rounded-3xl text-left lg:w-3/5"},[_vm._m(1),_c('form',{ref:"loginForm",attrs:{"id":"loginForm"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',{staticClass:"h-22 flex flex-col justify-between mt-3"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal",class:{
                  'ring-2 ring-red-500 bg-red-400':
                    (!_vm.$v.form.email.required && _vm.submitted) ||
                    (_vm.loginFailed && _vm.submitted),
                },attrs:{"type":"text","id":"email","name":"email","placeholder":"john@emailaddress.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('div',{staticClass:"h-3"},[(!_vm.$v.form.email.email && _vm.submitted)?_c('span',{staticClass:"absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"},[_vm._v("Invalid email.")]):_vm._e(),(!_vm.$v.form.email.required && _vm.submitted)?_c('span',{staticClass:"absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"},[_vm._v("Required")]):_vm._e()])]),_c('div',{staticClass:"h-22 flex flex-col justify-between my-3"},[_vm._m(3),_c('div',{staticClass:"relative flex items-center"},[(([_vm.showPassword ? 'text' : 'password'])==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],ref:"password",staticClass:"py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal",class:{
                    'ring-2 ring-red-500':
                      (!_vm.$v.form.password.required && _vm.submitted) ||
                      (_vm.loginFailed && _vm.submitted),
                  },attrs:{"id":"password","name":"password","placeholder":"Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.password)?_vm._i(_vm.form.password,null)>-1:(_vm.form.password)},on:{"change":function($event){var $$a=_vm.form.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "password", $$c)}}}}):(([_vm.showPassword ? 'text' : 'password'])==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],ref:"password",staticClass:"py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal",class:{
                    'ring-2 ring-red-500':
                      (!_vm.$v.form.password.required && _vm.submitted) ||
                      (_vm.loginFailed && _vm.submitted),
                  },attrs:{"id":"password","name":"password","placeholder":"Password","type":"radio"},domProps:{"checked":_vm._q(_vm.form.password,null)},on:{"change":function($event){return _vm.$set(_vm.form, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],ref:"password",staticClass:"py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal",class:{
                    'ring-2 ring-red-500':
                      (!_vm.$v.form.password.required && _vm.submitted) ||
                      (_vm.loginFailed && _vm.submitted),
                  },attrs:{"id":"password","name":"password","placeholder":"Password","type":[_vm.showPassword ? 'text' : 'password']},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),(!_vm.showPassword)?_c('span',{staticClass:"font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_vm._v(" visibility ")]):_vm._e(),(_vm.showPassword)?_c('span',{staticClass:"font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_vm._v(" visibility_off ")]):_vm._e()]),_c('div',{staticClass:"h-3"},[(!_vm.$v.form.password.required && _vm.submitted)?_c('span',{staticClass:"absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"},[_vm._v("Required")]):_vm._e()])]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-gray-400 font-semibold text-sm font-Satoshi tracking-wide"},[_vm._v(" Forgot your password? "),_c('router-link',{staticClass:"text-navy-500 hover:text-gray-500",attrs:{"to":"/reset"}},[_vm._v("Reset it here.")])],1)]),_c('div',{staticClass:"mt-3"},[_c('input',{staticClass:"flex text-center flex-row items-center justify-center h-13 w-full bg-teal hover:bg-opacity-70 rounded-xl font-Satoshi text-white font-bold tracking-wider text-sm",class:{
                  'bg-green-500': _vm.submitIsSuccess,
                  'bg-red-500': _vm.submitIsError,
                  'bg-teal': _vm.submitIsSuccess == _vm.submitIsError,
                },attrs:{"type":"submit"},domProps:{"value":_vm.submitButtonValue}})]),(_vm.loginFailed)?_c('div',{staticClass:"h-auto p-3 mt-4 text-justify bg-red-200 rounded-lg"},[_c('span',{staticClass:"text-red-500 font-Satoshi font-medium h-full w-full"},[_vm._v(" "+_vm._s(_vm.errorMsg))])]):_vm._e()])])])]),_c('img',{staticClass:"hidden lg:block absolute w-28 top-60 left-32",attrs:{"src":require("@/assets/vectors/particles/whiteSquiggle.svg"),"alt":""}}),_c('img',{staticClass:"hidden lg:block absolute w-16 bottom-20 left-20",attrs:{"src":require("@/assets/vectors/particles/blueSquareWhiteOutline.svg"),"alt":""}}),_c('img',{staticClass:"hidden lg:block absolute w-5 top-90 left-160 ml-24",attrs:{"src":require("@/assets/vectors/particles/whiteCircleOutline.svg"),"alt":""}}),_c('img',{staticClass:"hidden lg:block absolute w-7 top-144 right-20 z-40",attrs:{"src":require("@/assets/vectors/particles/orangeCircleWhiteOutline.svg"),"alt":""}})]),_c('div',{staticClass:"hidden lg:block lg:absolute bg-navy-800 z-0 right-0 h-full w-half-screen-w top-0"}),_c('customFooter',{staticClass:"mt-10 lg:hidden",attrs:{"hideText":""}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-10 lg:w-1/2 z-10"},[_c('img',{staticClass:"mx-auto lg:w-3/4",attrs:{"src":require("@/assets/images/CompositionLogin.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-16 flex flex-col justify-between"},[_c('div',{staticClass:"font-ClashDisplay font-semibold text-2xl"},[_vm._v(" Welcome Back "),_c('span',{staticClass:"text-sunray"},[_vm._v("!")])]),_c('div',{staticClass:"font-Satoshi font-bold text-base"},[_vm._v(" Login to your account here. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between text-sm"},[_c('label',{staticClass:"font-bold",attrs:{"for":"email"}},[_vm._v("Email Address")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between text-sm"},[_c('label',{staticClass:"font-bold",attrs:{"for":"password"}},[_vm._v("Password")])])}]

export { render, staticRenderFns }