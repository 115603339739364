import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc } from "firebase/firestore";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDdq7H_CNJ29BAk83ZO_qAr5m2NwabCuXM",
  authDomain: "pursuit-crypto.firebaseapp.com",
  projectId: "pursuit-crypto",
  storageBucket: "pursuit-crypto.appspot.com",
  messagingSenderId: "788028403108",
  appId: "1:788028403108:web:ac65b6ce30c702467b2531",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);
