<!-- Nav -->
<template>
  <div>
    <div
      class="flex flex-row justify-around items-center w-full bg-navy-500 h-22 z-50 relative"
    >
      <div
        class="h-10 w-10 border-2 border-accent rounded-lg p-2 ease-in-out duration-300"
        :class="{ 'border-opacity-0': this.isOpen }"
      >
        <div id="nav-icon" :class="{ open: this.isOpen }" @click="toggleOpen">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center w-1/2">
        <img src="@/assets/vectors/logo.svg" alt="" class="lg:h-8 1xl:h-10" />
        <span class="text-white font-CerebriPro font-bold pt-0.5 ml-2"
          >Pursuit Crypto
        </span>
      </div>
      <div class="h-10 w-10"></div>
    </div>
    <div
      class="h-80 bg-navy-500 absolute w-full duration-1000 z-40 ease-in-out box-border border-t-2 border-b-2 border-accent text-white font-CerebriPro text-medium flex flex-col px-9 md:px-16 py-12 font-bold items-start justify-between tracking-wider"
      :class="{ 'top-22': this.isOpen, '-top-80': !this.isOpen }"
    >
      <router-link
        to="/"
        class="cursor-point hover:text-teal"
        v-if="!isDashboard"
        >Home</router-link
      >
      <router-link
        to="/about"
        class="cursor-point hover:text-redpink"
        v-if="!isDashboard"
        >About Us</router-link
      >
      <router-link
        to="/contact"
        class="cursor-point hover:text-sunray"
        v-if="!isDashboard"
        >Get In Touch</router-link
      >
      <router-link
        to="/dashboard"
        class="cursor-point hover:text-thegreen"
        v-if="user.loggedIn && !isDashboard"
      >
        {{ user.data.firstName }}'s dashboard
      </router-link>
      <div
        class="flex flex-row items-center h-auto w-full"
        v-if="isDashboard && user.loggedIn"
      >
        <img src="@/assets/vectors/chart-2.svg" alt="" class="mr-3" />
        <router-link
          to="/dashboard/earnings"
          class="cursor-point hover:text-sunray"
          v-if="isDashboard"
          >Your Earnings</router-link
        >
      </div>
      <div
        class="flex flex-row items-center h-auto w-full"
        v-if="isDashboard && user.loggedIn"
      >
        <img src="@/assets/vectors/money-send.svg" alt="" class="mr-3" />
        <router-link
          to="/dashboard/payouts/history"
          class="cursor-point hover:text-sunray"
          v-if="isDashboard"
          >Payout History</router-link
        >
      </div>

      <div
        class="flex flex-row items-center h-auto w-full"
        v-if="isDashboard && user.loggedIn"
      >
        <img src="@/assets/vectors/empty-wallet.svg" alt="" class="mr-3" />

        <router-link
          to="/dashboard/payouts"
          class="cursor-point hover:text-sunray"
          v-if="isDashboard"
          >Payout Settings</router-link
        >
      </div>

      <div
        class="flex flex-row items-center h-auto w-full"
        v-if="isDashboard && user.loggedIn"
      >
        <img src="@/assets/vectors/home-2.svg" alt="" class="mr-3" />
        <router-link
          to="/"
          class="cursor-point hover:text-sunray"
          v-if="isDashboard"
          >Back to Home</router-link
        >
      </div>
      <div
        class="flex flex-row items-center h-auto w-full"
        v-if="isDashboard && user.loggedIn"
      >
        <img src="@/assets/vectors/logout.svg" alt="" class="mr-3" />
        <span class="cursor-point hover:text-sunray" @click="signOutUser"
          >Sign Out</span
        >
      </div>

      <router-link
        to="/login"
        class="cursor-point hover:text-thegreen"
        v-if="!user.loggedIn && !isDashboard"
        >Login</router-link
      >
      <span
        class="cursor-point hover:text-thegreen"
        v-if="user.loggedIn && !isDashboard"
        @click="signOutUser"
        >Sign Out</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "navbar",
  props: {
    isDashboard: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  methods: {
    ...mapActions(["signOutUser"]),
    toggleOpen: function () {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#nav-icon {
  height: 20px;
  width: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 9px;
}

#nav-icon span:nth-child(4) {
  top: 18px;
}

#nav-icon.open span:nth-child(1),
#nav-icon.open span:nth-child(4) {
  opacity: 0;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
</style>
