import Vue from "vue";
import VueRouter from "vue-router";

import Landing from "@/views/Landing.vue";
import Contact from "@/views/Contact.vue";
import About from "@/views/About.vue";
import SendEmail from "@/views/SendEmail.vue";
import Login from "@/views/Login.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import DashboardEarnings from "@/views/DashboardEarnings.vue";
import DashboardPayouts from "@/views/DashboardPayouts.vue";
import DashboardPayoutsHistory from "@/views/DashboardPayoutsHistory.vue";
import PageNotFound from "@/views/PageNotFound.vue";

import test from "@/components/test.vue";

import { store } from "../store.js";

import { auth } from "../firebase.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/about",
    name: "About us",
    component: About,
  },
  {
    path: "/reset",
    name: "Reset",
    component: SendEmail,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/dashboard/earnings",
    name: "Earnings",
    component: DashboardEarnings,
    meta: {
      requiresAuth: true,
      requiresCryptoPricesUpdate: true,
    },
  },
  {
    path: "/dashboard/payouts/history",
    name: "Payouts History",
    component: DashboardPayoutsHistory,
    meta: {
      requiresAuth: true,
      requiresCryptoPricesUpdate: true,
    },
  },
  {
    path: "/dashboard/payouts",
    name: "Payouts",
    component: DashboardPayouts,
    meta: {
      requiresAuth: true,
      requiresCryptoPricesUpdate: true,
    },
  },
  {
    path: "/dashboard",
    name: "Earnings",
    redirect: "/dashboard/earnings",
    meta: {
      requiresAuth: true,
      requiresCryptoPricesUpdate: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },

  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuth = store.getters.user.loggedIn;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUnauth = to.matched.some(
    (record) => record.meta.requiresUnauth
  );

  const requiresCryptoPricesUpdate = to.matched.some(
    (record) => record.meta.requiresCryptoPricesUpdate
  );

  if (requiresCryptoPricesUpdate) store.dispatch("updateCryptoPrices");

  if (requiresUnauth && isAuth) next("/");
  else if (requiresAuth && !isAuth) next("login");
  //   else if (requiresAuth && isAuth) {
  //     auth
  //       .verifyIdToken(store.getters.user.token, true)
  //       .then(() => {
  //         next();
  //       })
  //       .catch(() => {
  //         store.dispatch("signOutUser");
  //       });
  //   }
  else next();
});

export default router;
