<template>
  <div class="h-full w-64">
    <div class="font-Satoshi text-white font-medium">
      <div
        class="flex items-center z-0"
        :class="{ 'justify-end': side == 'right' }"
        @click="toggleOpen"
      >
        <div
          class="flex items-center p-1 px-2 rounded-lg cursor-pointer"
          :class="{
            'bg-gradientGray bg-opacity-60': isOpen && hasBackground,
            'hover:bg-gradientGray hover:bg-opacity-60': hasBackground,
          }"
        >
          <div :class="`text-${textSize}`">{{ title }}</div>
          <img
            src="@/assets/vectors/dropDownArrow.svg"
            class="ml-2 h-1.5 transform ease-in-out duration-300"
            :class="{ 'rotate-180': isOpen }"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="w-64 bg-navy-500 h-auto transform translate-y-0 ease-in-out duration-500 z-10 rounded-xl border-accent border mt-3 p-4 py-2 absolute"
      :class="{
        '-translate-y-8 opacity-0': !isOpen,
        'pointer-events-none': isHidden,
      }"
    >
      <dropdownItem
        v-for="item in Object.keys(items)"
        :key="item"
        @valueSelected="handleSelect"
        :itemLabel="item"
        :isSelected="items[item].isSelected"
        :itemValue="items[item].value"
        :disabled="items[item].disabled"
      >
      </dropdownItem>
    </div>
  </div>
</template>
<script>
import dropdownItem from "./dropdownItem.vue";

export default {
  components: { dropdownItem },

  props: {
    items: {},
    side: String,
    textSize: String,
    hasBackground: Boolean,
  },
  data() {
    return {
      isOpen: false,
      isHidden: true,
      title: this.selectedValue,
    };
  },
  created() {
    if (this.items) {
      for (let item of Object.keys(this.items)) {
        if (this.items[item].isSelected) this.title = item;
      }
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
      setTimeout(() => {
        this.isHidden = !this.isHidden;
      }, 300);
    },
    handleSelect(label) {
      this.toggleOpen();
      for (var item of Object.keys(this.items)) {
        this.items[item].isSelected = false;
      }
      this.items[label].isSelected = !this.items[label].isSelected;
      this.title = label;
      this.$emit("input", this.items[label].value);
    },
  },
};
</script>
