import Vue from "vue";
import Vuelidate from "vuelidate";
import VueCompositionApi from "@vue/composition-api";
import VueApexCharts from "vue-apexcharts";
import VueCurrencyFilter from "vue-currency-filter";

import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import { auth } from "./firebase.js";

import titleMixin from "./mixins/titleMixin";

import "./css/tailwind.css";
import "./css/fonts.css";
import "./css/addons.css";

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueCompositionApi);

Vue.use(VueCurrencyFilter, {
  name: "moneyFormat",
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.mixin(titleMixin);

auth.onAuthStateChanged((user) => {
  store.dispatch("commitUser", user);
});

new Vue({
  router,
  store: store,
  render: (h) => h(App),
  validations: {},
}).$mount("#app");
