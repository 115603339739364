<template>
  <div class="bg-navy-500 lg:h-screen w-screen relative">
    <mobileNavbar class="lg:hidden" />
    <loginNavbar
      rightText="Don’t have an account? Contact us!"
      linkTo="/contact"
      class="hidden lg:block lg:absolute z-40"
    />
    <div
      class="lg:flex lg:relative lg:flex-row lg:justify-evenly items-center h-full lg:max-w-screen-3xl mx-auto"
    >
      <div class="p-10 lg:w-1/2 z-10">
        <img
          src="@/assets/images/CompositionLogin.png"
          alt=""
          class="mx-auto lg:w-3/4"
        />
      </div>
      <div
        class="lg:w-1/2 lg:bg-navy-800 z-30 lg:h-full lg:flex lg:items-center lg:justify-center lg:py-24"
      >
        <div
          class="p-3 lg:w-full lg:h-full lg:flex lg:justify-center lg:items-center"
        >
          <div class="bg-white p-8 rounded-3xl text-left lg:w-3/5">
            <div class="h-16 flex flex-col justify-between">
              <div class="font-ClashDisplay font-semibold text-2xl">
                Welcome Back <span class="text-sunray">!</span>
              </div>
              <div class="font-Satoshi font-bold text-base">
                Login to your account here.
              </div>
            </div>
            <form id="loginForm" ref="loginForm" @submit.prevent="login">
              <div class="h-22 flex flex-col justify-between mt-3">
                <div class="flex justify-between text-sm">
                  <label for="email" class="font-bold">Email Address</label>
                </div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  class="py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal"
                  placeholder="john@emailaddress.com"
                  v-model="form.email"
                  :class="{
                    'ring-2 ring-red-500 bg-red-400':
                      (!$v.form.email.required && submitted) ||
                      (loginFailed && submitted),
                  }"
                />
                <div class="h-3">
                  <span
                    v-if="!$v.form.email.email && submitted"
                    class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                    >Invalid email.</span
                  >
                  <span
                    v-if="!$v.form.email.required && submitted"
                    class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                    >Required</span
                  >
                </div>
              </div>
              <div class="h-22 flex flex-col justify-between my-3">
                <div class="flex justify-between text-sm">
                  <label for="password" class="font-bold">Password</label>
                </div>
                <div class="relative flex items-center">
                  <input
                    :type="[showPassword ? 'text' : 'password']"
                    id="password"
                    name="password"
                    ref="password"
                    class="py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal"
                    :class="{
                      'ring-2 ring-red-500':
                        (!$v.form.password.required && submitted) ||
                        (loginFailed && submitted),
                    }"
                    placeholder="Password"
                    v-model="form.password"
                  />
                  <span
                    class="font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600"
                    @click="showPassword = !showPassword"
                    v-if="!showPassword"
                  >
                    visibility
                  </span>
                  <span
                    class="font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600"
                    @click="showPassword = !showPassword"
                    v-if="showPassword"
                  >
                    visibility_off
                  </span>
                </div>
                <div class="h-3">
                  <span
                    v-if="!$v.form.password.required && submitted"
                    class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                    >Required</span
                  >
                </div>
              </div>

              <div class="mt-3">
                <div
                  class="text-gray-400 font-semibold text-sm font-Satoshi tracking-wide"
                >
                  Forgot your password?
                  <router-link
                    to="/reset"
                    class="text-navy-500 hover:text-gray-500"
                    >Reset it here.</router-link
                  >
                </div>
              </div>
              <div class="mt-3">
                <input
                  type="submit"
                  class="flex text-center flex-row items-center justify-center h-13 w-full bg-teal hover:bg-opacity-70 rounded-xl font-Satoshi text-white font-bold tracking-wider text-sm"
                  :class="{
                    'bg-green-500': submitIsSuccess,
                    'bg-red-500': submitIsError,
                    'bg-teal': submitIsSuccess == submitIsError,
                  }"
                  :value="submitButtonValue"
                />
              </div>
              <div
                class="h-auto p-3 mt-4 text-justify bg-red-200 rounded-lg"
                v-if="loginFailed"
              >
                <span
                  class="text-red-500 font-Satoshi font-medium h-full w-full"
                >
                  {{ errorMsg }}</span
                >
              </div>
            </form>
          </div>
        </div>
      </div>
      <img
        src="@/assets/vectors/particles/whiteSquiggle.svg"
        alt=""
        class="hidden lg:block absolute w-28 top-60 left-32"
      />
      <img
        src="@/assets/vectors/particles/blueSquareWhiteOutline.svg"
        alt=""
        class="hidden lg:block absolute w-16 bottom-20 left-20"
      />
      <img
        src="@/assets/vectors/particles/whiteCircleOutline.svg"
        alt=""
        class="hidden lg:block absolute w-5 top-90 left-160 ml-24"
      />
      <img
        src="@/assets/vectors/particles/orangeCircleWhiteOutline.svg"
        alt=""
        class="hidden lg:block absolute w-7 top-144 right-20 z-40"
      />
    </div>
    <div
      class="hidden lg:block lg:absolute bg-navy-800 z-0 right-0 h-full w-half-screen-w top-0"
    ></div>
    <customFooter hideText class="mt-10 lg:hidden" />
  </div>
</template>

<script>
import customFooter from "@/components/generalComponents/customFooter.vue";
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import loginNavbar from "@/components/generalComponents/loginNavbar.vue";

import { required, email } from "vuelidate/lib/validators";

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

export default {
  name: "Login",
  components: {
    loginNavbar,
    customFooter,
    mobileNavbar,
  },
  data() {
    return {
      submitButtonValue: "Login",
      submitIsSuccess: false,
      submitIsError: false,
      loginFailed: false,
      submitted: false,
      showPassword: false,
      errorMsg: "",
      form: {
        email: "",
        password: "",
      },
      auth,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  title() {
    return `Pursuit Crypto - Login`;
  },
  methods: {
    login() {
      this.submitButtonValue = "...";
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        signInWithEmailAndPassword(
          this.auth,
          this.form.email,
          this.form.password
        )
          .then(() => {
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            switch (error.code) {
              case "auth/invalid-email":
                this.errorMsg = "Invalid email";
                break;
              case "auth/user-not-found":
                this.errorMsg = "No account with that email was found";
                break;
              case "auth/wrong-password":
                this.errorMsg = "Incorrect password";
                break;
              default:
                this.errorMsg = "Email or password was incorrect";
                break;
            }
            this.loginFailed = true;
          });
      }
      this.submitButtonValue = "Login";
    },
  },
};
</script>
