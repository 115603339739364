<template>
  <div class="h-screen bg-navy-500">
    <div class="bg-navy-500 h-24">
      <navbar class="hidden lg:flex" />
      <mobileNavbar class="lg:hidden" />
      <div class="my-10 max-w-screen-3xl mx-auto">
        <div
          class="font-ClashDisplay font-semibold text-white text-6xl flex flex-col items-center h-half-screen-h justify-around lg:py-24"
        >
          <img src="@/assets/vectors/pageNotFound.svg" alt="" class="h-18" />
          <div>404 Not Found<span class="text-sunray">.</span></div>
          <router-link
            to="/"
            class="font-Satoshi text-base bg-teal h-16 w-48 flex items-center justify-center rounded-full hover:opacity-75"
            >Return to Home</router-link
          >
        </div>
        <img
          src="@/assets/vectors/particles/whiteSquiggle.svg"
          alt=""
          class="hidden lg:block absolute w-24 lg:top-64 lg:right-20 right-96 top-144"
        />
        <img
          src="@/assets/vectors/particles/redCircleWhiteOutline.svg"
          alt=""
          class="hidden lg:block absolute w-8 top-100 lg:left-64 z-40"
        />
        <img
          src="@/assets/vectors/particles/whiteCircleOutline.svg"
          alt=""
          class="hidden lg:block absolute lg:bottom-100 lg:left-16 xl:bottom-144 3xl:left-144"
        />
      </div>
      <customFooter class="w-full" />
    </div>
  </div>
</template>

<script>
import navbar from "@/components/generalComponents/navbar.vue";
import customFooter from "@/components/generalComponents/customFooter.vue";
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";

export default {
  name: "PageNotFound",
  components: {
    navbar,
    customFooter,
    mobileNavbar,
  },
  title() {
    return `Pursuit Crypto - 404`;
  },
};
</script>
