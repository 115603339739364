<template>
  <div class="bg-navy-500 lg:h-screen w-screen lg:relative">
    <mobileNavbar class="lg:hidden" />
    <loginNavbar
      rightText="Already know your password? Login!"
      class="hidden lg:block lg:absolute z-40"
      linkTo="/login"
    />
    <div
      class="flex flex-col-reverse lg:relative lg:flex-row lg:justify-evenly items-center h-full lg:max-w-screen-3xl mx-auto"
    >
      <div
        class="w-full lg:w-1/2 lg:bg-navy-800 z-30 lg:h-full lg:flex lg:items-center lg:justify-center lg:py-24"
      >
        <div
          class="p-3 w-full lg:h-full lg:flex lg:justify-center lg:items-center"
        >
          <div class="bg-white p-8 rounded-3xl text-left lg:w-3/5">
            <div class="h-16 flex flex-col justify-between">
              <div class="font-ClashDisplay font-semibold text-2xl">
                Reset Password <span class="text-sunray">.</span>
              </div>
              <div
                class="font-Satoshi font-bold text-base leading-5 md:leading-normal"
              >
                We’ll send a link to reset your password.
              </div>
            </div>
            <form
              id="sendEmailForm"
              ref="sendEmailForm"
              @submit.prevent="sendEmail"
            >
              <div class="h-22 flex flex-col justify-between mt-4 md:mt-3">
                <div class="flex justify-between text-sm">
                  <label for="email" class="font-bold">Email Address</label>
                </div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  class="py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal"
                  placeholder="john@emailaddress.com"
                  v-model="form.email"
                />
                <div class="h-3">
                  <span
                    v-if="!$v.form.email.email && submitted"
                    class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                    >Invalid email.</span
                  >
                  <span
                    v-if="!$v.form.email.required && submitted"
                    class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                    >Required</span
                  >
                </div>
              </div>
              <div
                class="h-auto p-3 my-4 text-justify bg-red-200 rounded-lg"
                v-if="sendingFailed"
              >
                <span
                  class="text-red-500 font-Satoshi font-medium h-full w-full"
                >
                  {{ errorMsg }}</span
                >
              </div>
              <div
                class="h-auto p-3 my-4 text-justify bg-green-200 rounded-lg"
                v-if="sendingSuccess"
              >
                <span
                  class="text-green-500 font-Satoshi font-medium h-full w-full"
                >
                  An email has been sent with a reset password link</span
                >
              </div>
              <div class="mt-3">
                <input
                  type="submit"
                  class="flex flex-row items-center justify-center h-13 w-full bg-teal hover:bg-opacity-70 rounded-xl font-Satoshi text-white font-bold tracking-wider text-sm"
                  value="Send reset link"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="p-10 lg:w-1/2">
        <img
          src="@/assets/images/CompositionReset2.png"
          alt=""
          class="mx-auto lg:w-3/4"
        />
      </div>
      <img
        src="@/assets/vectors/particles/blueSquareWhiteOutline.svg"
        alt=""
        class="hidden lg:block absolute w-12 top-90 left-16 z-40"
      />
      <img
        src="@/assets/vectors/particles/whiteTriangleOutline.svg"
        alt=""
        class="hidden absolute lg:block w-6 bottom-96 left-32 z-40"
      />
      <img
        src="@/assets/vectors/particles/whiteCircleOutline.svg"
        alt=""
        class="hidden absolute lg:block w-7 right-144 bottom-24 xl:right-160"
      />
      <img
        src="@/assets/vectors/particles/redCircleWhiteOutline.svg"
        alt=""
        class="hidden absolute lg:block w-7 right-16 bottom-120"
      />
      <img
        src="@/assets/vectors/particles/whiteSquiggle.svg"
        alt=""
        class="hidden absolute lg:block w-22 right-1 top-120 transform -rotate-12"
      />
    </div>
    <div
      class="hidden lg:block lg:absolute bg-navy-800 z-0 left-0 h-full w-half-screen-w top-0"
    ></div>
  </div>
</template>

<script>
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import loginNavbar from "@/components/generalComponents/loginNavbar.vue";

import { required, email } from "vuelidate/lib/validators";

import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";

export default {
  name: "Login",
  components: {
    mobileNavbar,
    loginNavbar,
  },
  data() {
    return {
      submitButtonValue: "Send reset link",
      sendingSuccess: false,
      submitted: false,
      sendingFailed: false,
      form: {
        email: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  title() {
    return `Pursuit Crypto - Reset`;
  },
  methods: {
    sendEmail() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        sendPasswordResetEmail(auth, this.form.email)
          .then(() => {
            // console.log("email sent");
            this.sendingSuccess = true;
            this.sendingFailed = false;
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, error);
            switch (error.code) {
              case "auth/invalid-email":
                this.errorMsg = "Invalid email";
                break;
              case "auth/user-not-found":
                this.errorMsg = "No account with that email was found";
                break;
              default:
                this.errorMsg = "Something went wrong";
            }
            this.sendingSuccess = false;
            this.sendingFailed = true;
          });
      }
    },
  },
};
</script>
