<template>
  <div
    class="flex border-t border-b border-gradientGray p-6"
    @click="createWallet(menuOptionInfo.coin)"
    :class="{
      'pointer-events-none bg-gradientGray bg-opacity-25 text-gradientGray':
        disabled,
    }"
  >
    <img
      :src="getImgUrl(menuOptionInfo.coin)"
      alt=""
      class="h-6"
      :class="{ 'opacity-25': disabled }"
    />
    <div class="ml-2 tracking-wide">{{ menuOptionInfo.name }}</div>
  </div>
</template>

<script>
export default {
  name: "WalletMenuOption",
  props: {
    menuOptionInfo: null,
    disabled: Boolean,
  },
  methods: {
    getImgUrl(pic) {
      return require(`@/assets/vectors/wallet-(${pic}).svg`);
    },
    createWallet(coin){
      this.$emit("createWallet", coin )
    }
  },
};
</script>
