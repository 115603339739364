<template>
  <div class="mx-auto lg:text-left h-auto">
    <div class="font-Satoshi text-white font-medium text-left lg:hidden">
      Your Earnings
    </div>
    <div v-if="Object.keys(payouts).length !== 0">
      <div class="bg-navy-500 p-6 rounded-xl my-2.5 lg:m-0 table w-full z-30">
        <div
          class="hidden lg:block font-Satoshi text-white font-medium text-left pb-6 z-50"
        >
          Your Earnings
        </div>
        <div class="flex justify-between w-full text-sm text-white">
          <span class="lg:w-1/3">AMOUNT</span>
          <span class="lg:w-2/9">DATE</span>
          <span class="hidden lg:block lg:w-2/9">STATUS</span>
          <span class="hidden lg:block lg:w-2/9">TX</span>
        </div>
        <div class="border border-gradientGray my-4" />
        <div>
          <payoutItem
            v-for="payout in payouts"
            :key="payout.id"
            :payoutInfo="payout"
          />
          <!-- <payoutItem :payoutInfo="payout"/> -->
        </div>
        <router-link
          class="font-Satoshi text-white text-opacity-60 hover:text-opacity-100 cursor-pointer"
          to="/dashboard/payouts/history"
          v-if="limited"
        >
          See full payout history
        </router-link>
      </div>
    </div>
    <div v-else>
      <div
        class="bg-navy-500 p-6 rounded-xl my-2.5 lg:m-0 table w-full z-30 text-center text-xl text-white font-Satoshi text-opacity-40"
      >
        No payouts yet
      </div>
    </div>
  </div>
</template>

<script>
import payoutItem from "@/components/dashboardComponents/payoutItem.vue";
import { mapActions } from "vuex";
export default {
  name: "payoutTable",
  components: {
    payoutItem,
  },
  props: {
    limited: Boolean,
  },
  data() {
    return {
      payouts: {},
    };
  },
  async created() {
    await this.getPayouts().then(async (response) => {
      this.payouts = response.data;
      //   console.log(this.payouts);
    });
  },
  methods: {
    ...mapActions(["getPayouts"]),
  },
};
</script>
