<template>
  <div
    class="bg-navy-500 lg:h-100"
    :class="{ 'h-90': !hideText, 'h-48': hideText }"
  >
    <div
      class="h-full px-6 flex flex-col justify-around lg:block lg:px-36 font-Satoshi max-w-screen-3xl mx-auto"
    >
      <div
        v-if="!hideText"
        class="flex flex-col lg:flex-row justify-between text-left text-white h-1/3 lg:h-64 lg:pt-10"
      >
        <div class="h-full lg:w-2/5 lg:h-2/3 flex flex-col justify-around">
          <div class="flex flex-row items-center w-1/2">
            <img src="@/assets/vectors/logo.svg" alt="" class="h-8 w-8 mr-2" />
            <span class="font-bold xl:text-lg w-full">Pursuit Crypto</span>
          </div>
          <div class="text-tiny md:text-base leading-relaxed">
            Creating an all-inclusive Crypto Mining experience for all.
          </div>
        </div>
        <div>
          <div
            class="hidden lg:flex flex-col xl:text-base h-4/5 justify-around"
          >
            <span class="font-bold xl:text-lg">Quick Links</span>
            <router-link to="/" class="hover:text-teal">Home</router-link>
            <router-link to="/about" class="hover:text-redpink"
              >About us</router-link
            >
            <router-link to="/contact" class="hover:text-sunray"
              >Get In Touch</router-link
            >
            <router-link to="/login" class="hover:text-thegreen"
              >Log in</router-link
            >
          </div>
        </div>
      </div>

      <div
        class="border-t-2 border-accent h-1/4 lg:h-16 lg:mt-10 flex flex-col lg:flex-row justify-between lg:items-end"
      >
        <span
          class="text-white text-tiny text-left lg:text-sm lg:font-semibold text-opacity-75 mt-8 lg:mt-0"
        >
          Copyright © 2022. All rights reserved.
        </span>
        <div
          class="flex flex-row w-16 lg:w-24 justify-between lg:justify-around"
        >
          <!-- <a
            href="https://twitter.com/PursuitCrypto"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/vectors/Twitter.svg"
              alt=""
              class="cursor-pointer"
            />
          </a> -->
          <a
            href="https://www.linkedin.com/company/pursuitcrypto/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/vectors/LinkedIn.svg"
              alt=""
              class="cursor-pointer"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideText: Boolean,
  },
  name: "customFooter",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
