<template>
  <div
    class="absolute flex items-center justify-center w-screen h-screen bg-opacity-75 ease-in-out duration-300 backdrop-filter backdrop-blur"
    :class="{ 'opacity-0 pointer-events-none': !isOpen }"
  >
    <div class="bg-navy-500 rounded-xl w-1/3 p-8 border-accent border">
      <div class="flex justify-between">
        <div class="font-semibold text-lg text-white font-Satoshi">
          Select a Coin
        </div>
        <div
          class="font-Icons text-lg font-bold text-white ease-in-out duration-300 transform hover:scale-125 cursor-pointer"
          @click="closeMenu"
        >
          close
        </div>
      </div>
      <walletMenuItem
        v-for="item in items"
        :key="item.value"
        :itemValue="item.coin"
        :itemLabel="item.name"
        :disabled="item.disabled"
        @valueSelected="handleSelect"
      />
    </div>
  </div>
</template>

<script>
import walletMenuItem from "@/components/dashboardComponents/walletMenuItem.vue";

export default {
  name: "walletMenu",
  components: {
    walletMenuItem,
  },
  data() {
    return {};
  },
  props: {
    isOpen: Boolean,
    items: {},
  },
  methods: {
    handleSelect(value) {
      this.$emit("valueSelected", value);
    },
    closeMenu() {
      this.$emit("closeMenu");
    },
  },
};
</script>
