<template>
  <div
    class="group cursor-pointer flex justify-between items-center p-3 w-full my-2 rounded-lg bg-gradientGray hover:bg-teal text-white font-Satoshi font-medium tracking-wide"
    @click="valueSelected"
    :class="{
      'pointer-events-none bg-gradientGray bg-opacity-25 text-gradientGray':
        disabled,
    }"
  >
    <div
      class="transform ease-in-out duration-300 translate-x-0 group-hover:translate-x-2"
    >
      {{ itemLabel }}
    </div>
    <img src="@/assets/vectors/tick-circle.svg" v-if="isSelected" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    itemLabel: String,
    itemValue: String,
    isSelected: Boolean,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    valueSelected() {
      this.$emit("valueSelected", this.itemLabel);
    },
  },
};
</script>
