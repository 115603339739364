<template>
  <div class="bg-green-200 h-screen w-screen img">
    <div @click="isHidden = !isHidden" class="absolute">open popup menu</div>
    <!-- <dropdown class="mx-auto" title="USD" side="right" :items="items">
    </dropdown> -->

    <walletMenu :isHidden="isHidden" :items="coinDropdownItems" />
  </div>
</template>
<script>
import dropdown from "./generalComponents/dropdown.vue";
import walletMenu from "@/components/dashboardComponents/walletMenu.vue";
export default {
  title: "test",
  components: {
    //dropdown,
    walletMenu,
  },
  data() {
    return {
      isHidden: true,

      coinDropdownItems: {
        "Bitcoin (BTC)": {
          isSelected: true,
          value: "btc",
        },
        "Ethereum (ETH)": {
          isSelected: false,
          value: "eth",
        },
        "Litecoin (LTC)": {
          isSelected: false,
          value: "ltc",
        },
        "Dogecoin (DOGE)": {
          isSelected: false,
          value: "doge",
        },
        "USD-Coin (USDC)": {
          isSelected: false,
          value: "usdc",
        },
      },
    };
  },
};
</script>

<style scoped>
.img {
  background-image: "@/assets/images/CompositionLogin.png";
}
</style>
