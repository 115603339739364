<template>
  <div class="lg:flex min-h-screen">
    <mobileNavbar class="lg:hidden" isDashboard> </mobileNavbar>
    <dashboardNavbar class="hidden lg:block sticky top-0" />
    <div id="earnings" class="bg-navy-900 h-full w-full min-h-screen lg:p-10">
      <div
        class="font-ClashDisplay font-semibold text-white text-3xl p-6 lg:pl-6 lg:pb-0 lg:text-left"
      >
        Payout Settings<span class="text-sunray">.</span>
      </div>
      <div
        class="lg:w-full lg:h-full lg:px-6 lg:grid lg:grid-cols-2 lg:gap-10 lg:my-22r"
      >
        <wallet
          v-for="wallet in wallets"
          :key="wallet.id"
          :walletInfo="wallet"
        />

        <!-- Add Wallet -->
        <div
          class="mx-auto px-6 pb-12 w-full lg:p-0 lg:min-h-356"
          v-if="wallets.length != 5"
        >
          <div
            class="p-6 py-22 rounded-lg my-2.5 dotted flex flex-col items-center justify-center lg:p-0 lg:h-full lg:my-0"
          >
            <img src="@/assets/vectors/wallet-add.svg" alt="" class="lg:mb-4" />
            <button
              class="text-white font-Satoshi font-medium"
              @click="addWalletIsOpen = !addWalletIsOpen"
            >
              Add a Wallet
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Menu -->
    <div
      class="fixed bottom-0 h-screen w-screen overflow-hidden pointer-events-none z-50 lg:hidden"
      :class="{
        'pointer-events-none': !addWalletIsOpen,
        'pointer-events-auto': addWalletIsOpen,
      }"
    >
      <div
        class="absolute h-screen bg-navy-500 w-screen transform ease-in-out duration-300 z-50 text-white font-Satoshi font-medium text-lg"
        :class="{ 'left-0': addWalletIsOpen, 'left-full': !addWalletIsOpen }"
      >
        <div class="w-full p-6 flex items-center justify-between">
          <div>Select a Coin</div>
          <img
            @click="addWalletIsOpen = !addWalletIsOpen"
            class="h-5 transform rotate-180"
            src="@/assets/vectors/arrowleft.svg"
          />
        </div>
        <walletMenuOption
          v-for="walletMenuOption in walletMenuOptions"
          :key="walletMenuOption.coin"
          :disabled="walletMenuOption.disabled"
          :menuOptionInfo="walletMenuOption"
          @createWallet="createWallet"
        />
      </div>
    </div>

    <walletMenu
      :isOpen="addWalletIsOpen"
      :items="walletMenuOptions"
      @closeMenu="addWalletIsOpen = !addWalletIsOpen"
      @valueSelected="createWallet"
    />
  </div>
</template>

<script>
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import dashboardNavbar from "@/components/generalComponents/dashboardNavbar.vue";
import wallet from "@/components/dashboardComponents/wallet.vue";
import { mapGetters, mapActions } from "vuex";
import walletMenuOption from "@/components/dashboardComponents/walletMenuOption.vue";
import walletMenu from "@/components/dashboardComponents/walletMenu.vue";

export default {
  components: {
    mobileNavbar,
    dashboardNavbar,
    wallet,
    walletMenuOption,
    walletMenu,
  },
  name: "Dashboard",
  data() {
    return {
      addWalletIsOpen: false,
      wallets: null,
      coinWalletCreated: {
        btc: false,
        eth: false,
        ltc: false,
        usdc: false,
        doge: false,
      },
      walletMenuOptions: {
        btc: {
          coin: "btc",
          name: "Bitcoin",
          disabled: false,
        },
        ltc: {
          coin: "ltc",
          name: "Litecoin",
          disabled: true,
        },
        eth: {
          coin: "eth",
          name: "Ethereum",
          disabled: true,
        },
        usdc: {
          coin: "usdc",
          name: "USDC",
          disabled: true,
        },
        doge: {
          coin: "doge",
          name: "Dogecoin",
          disabled: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  async created() {
    await this.getWallets()
      .then((response) => {
        // console.log(response.data);
        this.wallets = response.data;
        if (this.wallets) {
          this.wallets.forEach((wallet) => {
            this.walletMenuOptions[wallet.coin]["disabled"] = true;
          });
        }
      })
      .catch((error) => {
        console.log(error.code, error);
      });
  },
  methods: {
    ...mapActions(["getWallets"]),
    async createWallet(coin) {
      this.addWalletIsOpen = false;
      await this.$store.dispatch("createWallet", coin).then(async () => {
        this.walletMenuOptions[coin]["disabled"] = true;
        await this.getWallets(this.user.data.uid).then((response) => {
          this.wallets = response.data;
        });
      });
    },
  },
};
</script>
<style scoped>
.dotted {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
