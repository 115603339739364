<!-- Nav -->
<template>
  <div
    class="px-24 flex flex-row items-center justify-center font-CerebriPro font-semibold pt-12 max-w-screen-3xl mx-auto"
  >
    <div class="w-1/3">
      <div
        class="text-white lg:text-xs xl:text-sm 1xl:text-base flex flex-row items-center justify-between w-4/5"
      >
        <router-link to="/" class="cursor-point hover:text-teal"
          >Home</router-link
        >
        <router-link to="/about" class="cursor-point hover:text-redpink"
          >About Us</router-link
        >
        <router-link to="/contact" class="cursor-point hover:text-sunray"
          >Get In Touch</router-link
        >
      </div>
    </div>

    <div class="flex flex-row items-center justify-center w-1/3">
      <img src="@/assets/vectors/logo.svg" alt="" class="lg:h-8 1xl:h-10" />
      <span class="text-white lg:text-base xl:text-lg 1xl:text-xl pt-0.5 ml-2"
        >Pursuit Crypto</span
      >
    </div>

    <div class="flex items-center justify-end w-1/3">
      <router-link
        v-if="user.loggedIn"
        class="text-white lg:text-xs xl:text-sm 1xl:text-base pt-1 mr-4 cursor-pointer hover:text-thegreen"
        to="/dashboard/earnings"
      >
        {{ user.data.firstName }}'s dashboard
      </router-link>
      <router-link
        v-else
        to="/login"
        class="text-white lg:text-xs xl:text-sm 1xl:text-base pt-1 mr-4 cursor-pointer hover:text-thegreen"
        >Log in to your account</router-link
      >
      <button
        v-if="user.loggedIn"
        class="bg-teal cursor-pointer hover:bg-opacity-75 rounded-xl h-11 w-11"
        @click="signOutUser"
      >
        <img
          src="@/assets/vectors/login.svg"
          alt=""
          class="h-full w-full p-3"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "navbar",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    ...mapActions(["signOutUser"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
