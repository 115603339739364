<!-- loginNav -->
<template>
  <div
    class="text-white font-CerebriPro min-w-64 w-64 h-screen bg-navy-500 p-6 px-4"
  >
    <div class="flex flex-row items-center w-full pb-6 px-2">
      <img src="@/assets/vectors/logo.svg" alt="" class="lg:h-8 1xl:h-10" />
      <span class="text-white font-CerebriPro font-bold pt-0.5 ml-2"
        >Pursuit Crypto
      </span>
    </div>
    <div class="border border-gradientGray"></div>
    <div
      class="font-Satoshi text-bgGray font-medium w-full text-left text-sm text-opacity-75 pt-6 pb-3 px-3"
    >
      DASHBOARD
    </div>
    <div
      class="flex flex-row items-center h-auto w-full px-3 py-3 rounded-xl hover:bg-teal my-2"
    >
      <img src="@/assets/vectors/chart-2.svg" alt="" class="mr-3" />
      <router-link
        to="/dashboard/earnings"
        class="cursor-point tracking-wide font-medium"
        >Your Earnings</router-link
      >
    </div>
    <div
      class="flex flex-row items-center h-auto w-full px-3 py-3 rounded-xl hover:bg-teal my-2"
    >
      <img src="@/assets/vectors/money-send.svg" alt="" class="mr-3" />
      <router-link
        to="/dashboard/payouts/history"
        class="cursor-point tracking-wide font-medium"
        >Payout History</router-link
      >
    </div>
    <div
      class="flex flex-row items-center h-auto w-full px-3 py-3 rounded-xl hover:bg-teal my-2"
    >
      <img src="@/assets/vectors/empty-wallet.svg" alt="" class="mr-3" />
      <router-link
        to="/dashboard/payouts"
        class="cursor-point tracking-wide font-medium"
        >Payout Settings</router-link
      >
    </div>
    <div
      class="flex flex-row items-center h-auto w-full px-3 py-3 rounded-xl hover:bg-teal my-2"
    >
      <img src="@/assets/vectors/home-2.svg" alt="" class="mr-3" />
      <router-link to="/" class="cursor-point tracking-wide font-medium"
        >Back to Home</router-link
      >
    </div>
    <div
      class="flex flex-row items-center h-auto w-full px-3 py-3 rounded-xl hover:bg-teal my-2"
    >
      <img src="@/assets/vectors/logout.svg" alt="" class="mr-3" />
      <span class="cursor-pointer" @click="signOutUser">Sign Out</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "dashboardNavbar",
  props: {
    rightText: String,
    linkTo: String,
  },
  methods: {
    ...mapActions(["signOutUser"]),
  },
};
</script>
