<template>
  <div class="bg-navy-500 lg:h-screen w-screen lg:relative">
    <mobileNavbar class="lg:hidden" />
    <loginNavbar
      rightText="Already know your password? Login!"
      linkTo="/login"
      class="hidden lg:block lg:absolute z-40"
    />
    <div
      class="flex flex-col-reverse lg:relative lg:flex-row lg:justify-evenly items-center h-full lg:max-w-screen-3xl mx-auto"
    >
      <div
        class="w-full lg:w-1/2 lg:bg-navy-800 z-30 lg:h-full lg:flex lg:items-center lg:justify-center lg:py-24"
      >
        <div
          class="p-3 w-full lg:h-full lg:flex lg:justify-center lg:items-center"
        >
          <div class="bg-white p-8 rounded-3xl text-left lg:w-3/5">
            <div class="h-16 lg:h-2 flex flex-col justify-between">
              <div class="font-ClashDisplay font-semibold text-2xl">
                Reset Password <span class="text-sunray">.</span>
              </div>
              <div class="font-Satoshi font-bold text-base">
                Set your new password here.
              </div>
            </div>
            <form
              @submit.prevent="resetPassword"
              class="text-left text-sm w-full flex flex-col h-3/4 justify-evenly lg:mt-14"
            >
              <div>
                <div class="h-22 flex flex-col justify-between mt-3">
                  <div class="flex justify-between text-sm">
                    <label for="password" class="font-bold">New Password</label>
                  </div>
                  <div class="relative flex items-center">
                    <input
                      :type="[showPassword ? 'text' : 'password']"
                      id="password"
                      name="password"
                      class="py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal"
                      placeholder="Password"
                      v-model="form.password"
                    />
                    <span
                      class="font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600"
                      @click="showPassword = !showPassword"
                      v-if="!showPassword"
                    >
                      visibility
                    </span>
                    <span
                      class="font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600"
                      @click="showPassword = !showPassword"
                      v-if="showPassword"
                    >
                      visibility_off
                    </span>
                  </div>
                  <div class="h-3">
                    <span
                      v-if="
                        (!$v.form.password.minLength && submitted) ||
                        (!$v.form.password.required && submitted)
                      "
                      class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                      >Password must be atleast 8 characters.</span
                    >
                    <span
                      v-if="!$v.form.password.maxLength && submitted"
                      class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                      >Password can't be longer than 15 characters.</span
                    >
                  </div>
                </div>
                <div class="h-22 flex flex-col justify-between mt-3">
                  <div class="flex justify-between text-sm">
                    <label for="confirmPassword" class="font-bold"
                      >Confirm New Password</label
                    >
                  </div>
                  <div class="relative flex items-center">
                    <input
                      :type="[showPassword ? 'text' : 'password']"
                      id="confirmPassword"
                      name="confirmPassword"
                      class="py-4 px-5 h-12 bg-iceblue w-full rounded-md font-Satoshi focus:outline-none focus:ring-2 focus:ring-teal"
                      placeholder="Password"
                      v-model="form.confirmPassword"
                    />
                    <span
                      class="font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600"
                      @click="showPassword = !showPassword"
                      v-if="!showPassword"
                    >
                      visibility
                    </span>
                    <span
                      class="font-Icons flex items-center h-10 w-10 text-2xl absolute right-0 cursor-pointer select-none text-gray-400 hover:text-gray-600"
                      @click="showPassword = !showPassword"
                      v-if="showPassword"
                    >
                      visibility_off
                    </span>
                  </div>

                  <div class="h-3">
                    <span
                      v-if="!$v.form.confirmPassword.required && submitted"
                      class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                      >Password must be atleast 8 characters.</span
                    >
                    <span
                      v-if="
                        !$v.form.confirmPassword.sameAs &&
                        submitted &&
                        $v.form.confirmPassword.required
                      "
                      class="absolute text-red-400 text-xs text-left font-bold font-Satoshi tracking-wide"
                      >Password doesn't match.</span
                    >
                  </div>
                </div>
                <div
                  class="h-auto p-3 my-4 text-justify bg-red-200 rounded-lg"
                  v-if="codeVerificationFailed"
                >
                  <span
                    class="text-red-500 font-Satoshi font-medium h-full w-full"
                  >
                    {{ errorMsg }}</span
                  >
                </div>
                <div
                  class="h-auto p-3 my-4 text-justify bg-green-200 rounded-lg"
                  v-if="passwordResetSuccess"
                >
                  <span
                    class="text-green-500 font-Satoshi font-medium h-full w-full"
                  >
                    Your password has been successfully reset. Redirecting to
                    login page...</span
                  >
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="flex flex-row items-center justify-center h-13 w-full bg-teal hover:bg-opacity-70 rounded-xl font-Satoshi text-white font-bold tracking-wider text-sm"
                    :value="submitButtonValue"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="p-10 lg:w-1/2">
        <img
          src="@/assets/images/CompositionReset2.png"
          alt=""
          class="mx-auto lg:w-3/4"
        />
      </div>
      <img
        src="@/assets/vectors/particles/blueSquareWhiteOutline.svg"
        alt=""
        class="hidden absolute lg:block w-12 top-90 left-16 z-40"
      />
      <img
        src="@/assets/vectors/particles/whiteTriangleOutline.svg"
        alt=""
        class="hidden absolute lg:block w-6 bottom-96 left-32 z-40"
      />
      <img
        src="@/assets/vectors/particles/whiteCircleOutline.svg"
        alt=""
        class="hidden absolute lg:block w-7 right-144 bottom-24 xl:right-160"
      />
      <img
        src="@/assets/vectors/particles/redCircleWhiteOutline.svg"
        alt=""
        class="hidden absolute lg:block w-7 right-16 bottom-120"
      />
      <img
        src="@/assets/vectors/particles/whiteSquiggle.svg"
        alt=""
        class="hidden absolute lg:block w-22 right-1 top-120 transform -rotate-12"
      />
    </div>
    <customFooter hideText class="mt-10 lg:hidden" />
    <div
      class="hidden lg:block lg:absolute bg-navy-800 z-0 left-0 h-full w-half-screen-w top-0"
    ></div>
  </div>
</template>

<script>
import customFooter from "@/components/generalComponents/customFooter.vue";
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";
import loginNavbar from "@/components/generalComponents/loginNavbar.vue";

import {
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";

import { verifyPasswordResetCode, confirmPasswordReset } from "@firebase/auth";
import { auth } from "../firebase";

export default {
  name: "ResetPassword",
  components: {
    customFooter,
    mobileNavbar,
    loginNavbar,
  },
  data() {
    return {
      submitButtonValue: "Save Changes",
      submitIsSuccess: false,
      submitIsError: false,
      submitted: false,
      codeVerificationSuccess: false,
      codeVerificationFailed: false,
      passwordResetSuccess: false,
      passwordResetFailed: false,
      errorMsg: null,
      accountEmail: null,
      form: {
        password: "",
        confirmPassword: "",
      },
      showPassword: true,
    };
  },
  title() {
    return `Pursuit Crypto - Reset Password`;
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
      confirmPassword: {
        required,
        sameAs: sameAs("password"),
      },
    },
  },
  mounted() {
    if (this.$route.query.oobCode) {
      this.verifyOOBCode(this.$route.query.oobCode);
    } else {
      this.errorMsg = "Invalid reset password link";
      this.codeVerificationFailed = true;
    }
  },
  methods: {
    resetPassword() {
      //   console.log(!this.$v.$invalid);
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid && this.codeVerificationSuccess) {
        confirmPasswordReset(
          auth,
          this.$route.query.oobCode,
          this.form.password
        ).then(() => {
          this.passwordResetSuccess = true;
          setTimeout(() => this.$router.push({ path: "/login" }), 5000);
        });
      }
    },
    verifyOOBCode(oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then((accountEmail) => {
          this.accountEmail = accountEmail;
          this.codeVerificationSuccess = true;
        })
        .catch((error) => {
          //   console.log(error);
          switch (error.code) {
            case "auth/expired-action-code":
              this.errorMsg = "Password reset link is expired";
              break;
            case "auth/invalid-action-code":
              this.errorMsg =
                "Password reset link is malformed or has already been used";
              break;
            case "auth/user-disabled":
              this.errorMsg = "Account is disabled";
              break;
            case "auth/user-not-found":
              this.errorMsg = "Incorrect password";
              break;
            default:
              this.errorMsg = "Something went wrong";
              break;
          }
          this.codeVerificationFailed = true;
        });
    },
  },
};
</script>
