<template id="03">
  <div
    class="bg-white h-screen max-h-1080 p-4 md:p-10 flex flex-col justify-between max-wscre"
  >
    <div
      class="bg-bgGray p-5 md:p-20 h-full w-full rounded-3xl flex flex-col justify-evenly lg:justify-start relative overflow-hidden lg:flex-row lg:px-28 lg:py-10 items-center max-w-screen-2xl mx-auto 3xl:justify-between"
    >
      <div
        class="lg:w-3/5 flex flex-col h-1/2 lg:h-4/5 justify-evenly xl:py-28 1xl:py-32 lg:justify-between"
      >
        <div>
          <div
            class="text-sunray font-CerebriPro font-bold text-sm tracking-widest md:text-base text-left z-10 mt-4"
          >
            YOU ARE SAFE WITH US
          </div>
          <div
            class="text-left text-4.5xl md:text-5xl leading-tight lg:leading-normal lg:text-4xl xl:text-5xl 1xl:text-6xl font-ClashDisplay font-semibold z-10"
          >
            Prioritized safety and security<span class="text-sunray">.</span>
          </div>
        </div>

        <div
          class="hidden lg:inline-block lg:text-base xl:text-lg font-semibold text-left w-5/6 3xl:text-xl lg:w-3/5 1xl:h-32"
        >
          We hold ourselves to the highest standards of safety and security.
          Every machine is protected under lock and key. Your investement is
          safe with us.
        </div>
      </div>
      <div class="h-1/2 lg:h-auto">
        <img
          src="@/assets/images/CompositionLP2.png"
          alt=""
          class="md:-mt-24 md:w-144 lg:mt-0 1xl:w-120 1xl:h-120 3xl:w-144 3xl:h-144"
        />
      </div>
      <img
        src="@/assets/vectors/03.svg"
        alt=""
        class="absolute w-52 lg:w-auto md:-ml-100 md:w-64 md:-mt-144 -mt-110 -ml-40 lg:-ml-20 lg:-mt-90"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "thirdSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
