<template>
  <div class="mx-auto p-6 lg:p-0 lg:m-0">
    <div class="bg-navy-500 p-6 rounded-xl my-2.5 lg:my-0">
      <div class="w-full flex justify-between items-center mb-8">
        <div
          class="flex text-white font-Satoshi text-lg font-medium tracking-wide"
        >
          <img :src="getImgUrl(walletInfo.coin)" alt="" />
          <div class="ml-3">
            {{ coinEnum[walletInfo.coin] }}
            <span class="uppercase hidden lg:inline"
              >({{ walletInfo.coin }})</span
            >
          </div>
        </div>
        <span
          class="text-lg leading-4 text-right"
          :class="statusColor"
          v-if="$v.$anyDirty"
          >{{ status }}</span
        >
      </div>
      <form
        id="walletForm"
        ref="walletForm"
        @submit.prevent="initiateWalletInfoUpdate"
      >
        <div class="mb-3">
          <div class="flex justify-between">
            <div class="font-Satoshi text-white font-medium text-left mb-2">
              Wallet Address
            </div>
            <span
              class="text-red-400 font-Satoshi font-medium"
              v-if="!$v.form.address.required && $v.$anyDirty"
              >Required</span
            >
            <span
              class="text-red-400 font-Satoshi font-medium"
              v-if="
                $v.form.address.required &&
                !this.cryptoAddressRegexValidator.test($v.form.address.$model)
              "
              >Invalid address format</span
            >
          </div>

          <div>
            <input
              type="text"
              id="address"
              name="address"
              ref="address"
              class="bg-gradientGray border-2 w-full border-gradientGray rounded-xl h-12 px-5 py-4 placeholder-opacity-75 text-white text-sm font-Satoshi font-medium tracking-wide bg-opacity-60 focus:ring-1 focus:ring-teal"
              placeholder=""
              v-model="form.address"
              @change="$v.$touch()"
            />
          </div>
        </div>
        <div class="my-4">
          <div class="flex justify-between">
            <div class="font-Satoshi text-white font-medium text-left mb-2">
              Payment Interval
            </div>
          </div>
          <div class="relative flex items-center">
            <!-- <input
              type="number"
              id="threshold"
              name="threshold"
              ref="threshold"
              step="any"
              maxlength="10"
              min="0.001"
              class="bg-gradientGray relative border-2 w-full border-gradientGray rounded-xl h-12 pl-10 px-5 py-4 placeholder-opacity-75 text-white text-sm font-Satoshi font-medium tracking-wide bg-opacity-60 focus:ring-1 focus:ring-teal"
              placeholder="0.00"
              v-model="form.threshold"
              @change="$v.$touch()"
            />
            <img
              class="font-Icons flex items-center text-lg text-white absolute left-3 cursor-pointer select-none"
              src="@/assets/vectors/money-send.svg"
            /> -->
          </div>
          <dropdown
            :title="paymentOption"
            side="left"
            :items="paymentOptions"
            textSize="base"
            hasBackground
            v-model="form.payoutInterval"
            @change="$v.$touch()"
            @blur="initiateWalletInfoUpdate"
          />
        </div>
        <input
          class="w-full bg-teal text-white font-Satoshi font-medium h-12 rounded-xl my-3 hover:opacity-75 cursor-pointer"
          :class="{
            'opacity-50 hover:opacity-50 cursor-not-allowed': !$v.$anyDirty,
          }"
          type="submit"
          value="Save"
          :disabled="!$v.$anyDirty"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import dropdown from "../generalComponents/dropdown.vue";

export default {
  name: "Wallet",
  props: {
    walletInfo: null,
  },
  components: {
    dropdown,
  },
  onCreate() {},
  created() {
    // console.log(this.walletInfo);
    this.cryptoAddressRegexValidator =
      this.cryptoAddressRegexValidators[this.walletInfo.coin];
  },
  data() {
    return {
      status: "Changes not saved",
      statusColor: "text-sunray",
      form: {
        address: this.walletInfo.address,
        payoutInterval: this.walletInfo.payoutInterval || "Monthly",
      },
      coinEnum: {
        eth: "Ethereum",
        btc: "Bitcoin",
        ltc: "Litecoin",
        doge: "Dogecoin",
        usdc: "USDC",
      },
      paymentOption: null,
      paymentOptions: {
        Monthly: {
          isSelected: true,
          value: null,
        },
      },
      cryptoAddressRegexValidators: {
        btc: new RegExp("^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$"),
        eth: new RegExp("^0x[a-fA-F0-9]{40}$"),
        ltc: new RegExp("^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$"),
        usdc: new RegExp("^0x[a-fA-F0-9]{40}$"),
        doge: new RegExp("^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$"),
      },
      cryptoAddressRegexValidator: null,
      imgSrc: "",
    };
  },
  validations: {
    form: {
      address: { required },
    },
  },
  methods: {
    ...mapActions(["updateWallet"]),
    async initiateWalletInfoUpdate() {
      //   console.log(this.$v.form.$model);
      if (
        !this.$v.form.invalid &&
        this.cryptoAddressRegexValidator.test(this.$v.form.address.$model)
      ) {
        // console.log(this.walletInfo);
        await this.updateWallet({
          userID: this.walletInfo.userID,
          coin: this.walletInfo.coin,
          id: this.walletInfo.id,
          ...this.$v.form.$model,
        })
          .then((response) => {
            // console.log(response.status);
            this.status = "Changes have been saved";
            this.statusColor = "text-green-500";
            setTimeout(() => {
              this.$v.$reset();
              this.status = "Changes not saved";
              this.statusColor = "text-sunray";
            }, 3000);
          })
          .catch((error) => {
            console.log(error.code, error);
            this.status = "Error saving changes";
            this.statusColor = "text-red-500";
          });
      }
    },
    getImgUrl(pic) {
      return require(`@/assets/vectors/wallet-(${pic}).svg`);
    },
  },
};
</script>
