<template>
  <div>
    <mobileNavbar class="lg:hidden"> </mobileNavbar>
    <firstSection></firstSection>
    <secondSection id="02"></secondSection>
    <thirdSection></thirdSection>
    <fourthSection />
    <customFooter />
  </div>
</template>

<script>
import firstSection from "@/components/landingPageSections/01.vue";
import secondSection from "@/components/landingPageSections/02.vue";
import thirdSection from "@/components/landingPageSections/03.vue";
import fourthSection from "@/components/landingPageSections/04.vue";
import customFooter from "@/components/generalComponents/customFooter.vue";
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";

export default {
  name: "Landing",
  components: {
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    customFooter,
    mobileNavbar,
  },
  title() {
    return `Pursuit Crypto`;
  },
};
</script>
