<template id="02">
  <div class="bg-white">
    <div
      class="max-w-screen-3xl lg:max-h-1080 mx-auto h-auto lg:h-screen md:p-20 px-6 py-9 lg:pt-36 lg:px-32 flex flex-col justify-between lg:py-36 lg:relative"
    >
      <div>
        <div
          class="text-sunray font-CerebriPro font-bold text-sm tracking-widest md:text-base text-left z-10"
        >
          WHY CHOOSE US
          <img
            src="@/assets/vectors/02.svg"
            alt=""
            class="absolute -ml-24 -mt-16"
          />
        </div>
        <div class="lg:flex flex-row justify-between items-center">
          <div
            class="text-black text-left text-4.5xl leading-tight lg:leading-normal lg:text-4xl xl:text-6xl font-ClashDisplay font-semibold z-10 3xl:w-2/5"
          >
            Thought into every decision<span class="text-sunray">.</span>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col py-10 lg:p-0 lg:flex-row justify-between items-center"
      >
        <div
          class="mb-5 lg:mb-0 bg-teal h-80 w-80 p-9 lg:h-60 lg:p-5 lg:w-60 xl:p-10 xl:w-80 xl:h-80 2xl:w-90 2xl:h-90 3xl:w-110 3xl:h-110 3xl:p-14 rounded-2xl flex flex-col justify-between"
        >
          <div
            class="h-20 w-20 lg:h-14 lg:w-14 xl:h-16 xl:w-16 1xl:h-20 1xl:w-20 3xl:h-24 3xl:w-24 bg-white bg-opacity-30 rounded-full flex items-center justify-center"
          >
            <img
              src="@/assets/vectors/card1.svg"
              alt=""
              class="lg:h-6 lg:w-6 xl:h-10 xl:w-10 2xl:h-10 3xl:h-14 3xl:w-14"
            />
          </div>
          <div
            class="text-white text-left text-lg lg:text-sm xl:text-lg 3xl:text-2xl font-bold font-CerebriPro leading-snug"
          >
            Tailor-made solutions for each investor.
          </div>
          <div
            class="text-white text-left text-sm font-normal leading-5 lg:text-sm xl:text-base 3xl:text-xl lg:font-semibold font-CerebriPro lg:leading-snug"
          >
            Every client receives personalized support to help them make the
            most of their investment.
          </div>
        </div>
        <div
          class="bg-sunray bg-opacity-50 mb-5 lg:mb-0 h-80 w-80 p-9 lg:h-60 lg:p-5 lg:w-60 xl:p-10 xl:w-80 xl:h-80 2xl:w-90 2xl:h-90 3xl:w-110 3xl:h-110 3xl:p-14 rounded-2xl flex flex-col justify-between"
        >
          <div
            class="h-20 w-20 lg:h-14 lg:w-14 xl:h-16 xl:w-16 1xl:h-20 1xl:w-20 3xl:h-24 3xl:w-24 bg-sunray rounded-full flex items-center justify-center"
          >
            <img
              src="@/assets/vectors/card2.svg"
              alt=""
              class="lg:h-6 lg:w-6 xl:h-10 xl:w-10 2xl:h-10 3xl:h-14 3xl:w-14"
            />
          </div>
          <div
            class="text-left text-lg lg:text-sm xl:text-lg 3xl:text-2xl font-bold font-CerebriPro leading-snug"
          >
            24/7 Analytics.
          </div>
          <div
            class="text-left text-sm font-normal leading-5 lg:text-sm xl:text-base 3xl:text-xl lg:font-semibold font-CerebriPro lg:leading-snug"
          >
            We provide all clients access to a private dashboard where you can
            monitor and manage your investment.
          </div>
        </div>
        <div
          class="bg-redpink bg-opacity-50 h-80 w-80 p-9 lg:h-60 lg:p-5 lg:w-60 xl:p-10 xl:w-80 xl:h-80 2xl:w-90 2xl:h-90 3xl:w-110 3xl:h-110 3xl:p-14 rounded-2xl flex flex-col justify-between"
        >
          <div
            class="h-20 w-20 lg:h-14 lg:w-14 xl:h-16 xl:w-16 1xl:h-20 1xl:w-20 3xl:h-24 3xl:w-24 bg-redpink rounded-full flex items-center justify-center"
          >
            <img
              src="@/assets/vectors/cpu.svg"
              alt=""
              class="lg:h-6 lg:w-6 xl:h-10 xl:w-10 2xl:h-10 3xl:h-14 3xl:w-14"
            />
          </div>
          <div
            class="text-left text-lg lg:text-sm xl:text-lg 3xl:text-2xl font-bold font-CerebriPro leading-snug"
          >
            Cutting-edge technology.
          </div>
          <div
            class="text-left text-sm font-normal leading-5 lg:text-sm xl:text-base 3xl:text-xl lg:font-semibold font-CerebriPro lg:leading-snug"
          >
            We use the latest mining hardware and innovative engineering
            solutions to maximize returns.
          </div>
        </div>
      </div>

      <img
        src="@/assets/vectors/particles/blackCork.svg"
        alt=""
        class="hidden lg:block absolute w-24 left-4 bottom-144"
      />
      <img
        src="@/assets/vectors/particles/orangeCircleBlackOutline.svg"
        alt=""
        class="hidden lg:block absolute w-8 left-64 bottom-12"
      />
      <img
        src="@/assets/vectors/particles/blueTriangleBlackOutline.svg"
        alt=""
        class="hidden lg:block absolute w-7 top-32 right-48 z-40"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "secondSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
