<template>
  <div
    class="w-full font-Satoshi text-white flex justify-between font-medium my-4"
  >
    <div class="lg:w-1/3">
      {{ payoutInfo.amount }}
      <span class="uppercase"> {{ payoutInfo.coin }}</span>
    </div>
    <span class="lg:w-2/9">{{ formatedPayoutDate }}</span>
    <span class="hidden lg:block lg:w-2/9 truncate">{{
      payoutInfo.status
    }}</span>
    <a
      :href="formatedTXLink"
      target="_blank"
      class="hidden lg:block lg:w-2/9 truncate hover:underline"
      >{{ payoutInfo.tx }}</a
    >
  </div>
</template>

<script>
export default {
  name: "payoutItem",
  props: {
    payoutInfo: {},
  },
  data() {
    return {
      formatedPayoutDate: null,
      formatedTXLink: null,
      months: {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      },
    };
  },
  created() {
    var date = new Date(this.payoutInfo.created._seconds * 1000);
    this.formatedPayoutDate = `${
      this.months[date.getMonth()]
    }  ${date.getMonth()} ${date.getFullYear()}`;

    this.formatedTXLink = `https://www.blockchain.com/btc/tx/${this.payoutInfo.tx}`;
  },
  methods: {},
};
</script>
