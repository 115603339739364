<template>
  <div>
    <div class="bg-navy-500 h-24">
      <navbar class="hidden lg:flex" />
      <mobileNavbar class="lg:hidden" />
    </div>
    <div class="h-64 lg:h-110 bg-navy-500 lg:relative">
      <div
        class="flex justify-center item-center h-full max-w-screen-3xl mx-auto lg:relative"
      >
        <div
          class="lg:w-1/2 lg:m-auto flex flex-col justify-center items-center"
        >
          <div class="text-sunray font-Satoshi font-bold tracking-widest z-10">
            ABOUT US
          </div>
          <div
            class="font-ClashDisplay text-4.5xl w-3/4 lg:w-auto lg:text-5xl xl:text-6.5xl font-semibold text-white z-10"
          >
            About Our Company<span class="text-sunray">.</span>
          </div>
          <img src="@/assets/vectors/01.svg" alt="" class="absolute -z-50" />
          <img
            src="@/assets/vectors/particles/whiteSquiggle.svg"
            alt=""
            class="hidden lg:block absolute w-24 left-0"
          />
          <img
            src="@/assets/vectors/particles/redSquareWhiteOutline.svg"
            alt=""
            class="hidden lg:block absolute w-14 lg: lg:bottom-20 right-20 z-40"
          />
        </div>
      </div>
    </div>

    <div class="bg-white flex flex-col lg:h-screen lg:max-h-1080">
      <div class="p-5 lg:py-12 lg:h-screen max-w-screen-3xl mx-auto">
        <div
          class="bg-bgGray max-w-screen-2xl p-6 md:p-20 h-full w-full rounded-3xl flex flex-col justify-evenly lg:justify-between relative overflow-hidden lg:flex-row lg:px-28 lg:py-10 items-center md:mx-auto 3xl:justify-between"
        >
          <div
            class="lg:h-full flex flex-col justify-between xl:py-20 2xl:py-1/6 text-left z-10"
          >
            <div
              class="leading-tight lg:w-4/5 3xl:w-full text-4.5xl md:text-5xl lg:text-6xl xl:text-5xl 1xl:text-5xl 3xl:text-6xl font-ClashDisplay font-semibold"
            >
              A little bit about who we are and what we do<span
                class="text-sunray"
                >.</span
              >
            </div>

            <div
              class="my-4 lg:my-0 text-medium leading-7 lg:text-lg 3xl:text-xl font-Satoshi font-medium lg:w-4/5"
            >
              We would love to talk to you more about what we can do and help
              you achieve what you are in pursuit of.
            </div>

            <router-link
              to="/contact"
              class="hover:bg-opacity-75 cursor-pointer lg:flex text-medium bg-teal mb-6 h-15 w-40 rounded-full text-white font-CerebriPro font-bold hidden flex-row items-center justify-center"
            >
              Get In Touch
            </router-link>
          </div>

          <div
            class="text-medium font-Satoshi leading-7 lg:w-2/3 md:text-base lg:flex lg:flex-col lg:justify-between lg:h-full 2xl:py-1/6 xl:py-20 lg:text-lg lg:tracking-wide xl:text-lg font-medium text-left 3xl:text-xl"
          >
            <p class="mb-6">
              Pursuit Crypto is a start-up focused on making crypto mining
              accessible and understandable to all. From crypto experts to
              industry newcomers, we aim to make investing approachable and
              lucrative.
            </p>

            <p class="mb-6">
              We offer an all-inclusive mining service where we understand your
              goals and help tailor our services for you to achieve them. Our
              team is well-rounded in every aspect of the process, from the
              interworkings to the fiscals and regulations. We are your one-stop
              shop.
            </p>

            <p class="mb-6">
              We have worked endlessly to optimize every part of the operation
              from hardware acquisition to the most cost-effective facility to
              operate the machines within. Our overhead is optimized to provide
              you with the maximum return on your investment.
            </p>
          </div>

          <router-link
            class="md:mr-auto lg:hidden bg-teal mb-6 h-15 w-40 lg:h-16 lg:w-48 rounded-full text-white font-CerebriPro font-bold flex flex-row items-center justify-center 3xl:h-20 3xl:w-60 hover:bg-opacity-75 cursor-pointer"
            to="/contact"
          >
            Get In Touch
          </router-link>
          <img
            src="@/assets/vectors/02.svg"
            alt=""
            class="absolute w-52 lg:w-auto md:w-64 top-10 -left-8 md:left-4 lg:left-16 lg:top-12 1xl:top-48 xl:top-24"
          />
        </div>
      </div>
    </div>
    <customFooter />
  </div>
</template>

<script>
import navbar from "@/components/generalComponents/navbar.vue";
import customFooter from "@/components/generalComponents/customFooter.vue";
import mobileNavbar from "@/components/generalComponents/mobileNavbar.vue";

export default {
  name: "About",
  components: {
    navbar,
    customFooter,
    mobileNavbar,
  },
  title() {
    return `Pursuit Crypto - About us `;
  },
};
</script>
